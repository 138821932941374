import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChangeUserData, LoginData, RegisterData } from '../entities';
import {
  ApiAuthResponse, ApiResponse, ApiUser, authApi,
} from '../../api';
import { omit } from 'lodash';
import { AuthUser } from '../../entities/AuthUser';
import { accessesApi } from '../../api/accessesApi/Accesses';
import { RegistrationStatuses } from '../../hooks/useRegistrationStatus';

export type AccessRequestParams = {
  company: string;
  organization: string;
  userType: string;
  position: string; // посада
  companyID: number;
  nodesIDs: number[];
  userID: number;
};

export const accessRequest = createAsyncThunk(
  'users/accessRequest',
  async (
    {
      userID,
      company,
      organization,
      userType,
      position,
      companyID,
      nodesIDs,
    }: AccessRequestParams,
    thunkAPI,
  ) => {
    console.log('accessRequest');
    try {
      const res = await accessesApi.createAccessReview({
        userID,
        company,
        organization,
        userType,
        companyID,
        nodesIDs,
      });
      const res2 = await accessesApi.sendPosition({
        userID,
        position, // посада
      });

      const user: ApiResponse<AuthUser> = await authApi.getUserMe();
      if (res.statusCode >= 200 || res.statusCode <= 300) {
        localStorage.setItem(
          '@registrationStatuses',
          RegistrationStatuses.access_pending,
        );
      }
      return user.data as AuthUser;
    } catch (e) {
      console.log('Error', e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getUser = createAsyncThunk(
  'users/getUser',
  async ({ userID }: { userID: number }, thunkAPI) => {
    console.log('getUser');
    try {
      const user: ApiResponse<AuthUser> = await authApi.getUserMe();
      return user.data as AuthUser;
    } catch (e) {
      console.log('Error', e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);
