import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { QRPublishingPieDashboardStyles } from './styles';
import { analyticsApi, ApiQRPublishingPie, TPostQRPublishing } from '../../../../api';
import { DataItem } from '../../../../pages';
import { PieChart } from '../../analyticsCharts';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type TQRPublishing = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#FF3800', '#5B5AFF', '#D7F64D', '#FFDB4D', '#FF831A', '#89DB33', '#CC2E4F', '#FFDB4D'];
export const QRPublishingPieDashboard: FC<TQRPublishing> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiQRPublishingPie | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostQRPublishing = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    };
    setLoading(true);
    analyticsApi.getQRPublishingPie(+companyID, filteredData).then(res => {
      setData(res.data);
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/qr-publishing-pie/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    }));
  }, [reQuery]);
  return <QRPublishingPieDashboardStyles>
      <div className="stats">
        <div className="stats-item">
          <p>Підключено точок ЦНАП до платформи:</p>
          <h6>{data?.cnapNodesCount}</h6>
        </div>
        <div className="stats-item">
          <p>Всього надійшло заповнених анкет:</p>
          <h6>{data?.reviewsCount}</h6>
        </div>
      </div>
      <div className="pies">
        {data?.pieItems.map(el => {
        const pies: DataItem[] = el.answers.map((e, index) => ({
          label: e.key,
          value: e.percentage,
          fill: colors[index],
          count: e.count
        }));
        return <div className="pies-item">
              <p>{el.key}</p>
              <PieChart width={dimensions.height / 4} height={dimensions.height / 4} data={pies} legend />
            </div>;
      })}
      </div>
    </QRPublishingPieDashboardStyles>;
};