import styled from 'styled-components';

export const DissatisfactionDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  overflow: auto;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    gap: 10px;
  }

  .rating {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }

    &-item {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 1440px) {
        font-size: 12px;
      }

      div {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }
  }

  .chart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    padding: 10px 0;

    &-legend {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 600px;

      @media screen and (max-width: 1440px) {
        gap: 5px;
      }
    }

    &-pie {
      box-sizing: border-box;
      padding: 24px;
      border: 1px solid #dee6eb;
      border-radius: 10px;
      width: 40%;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        @media screen and (max-width: 1440px) {
          font-size: 10px;
        }
      }

      div {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }
  }
`;
