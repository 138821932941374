import React, { FC } from 'react';
import { NameCustomFieldStyles } from './NameCustomFieldStyles';
type TNameCustomField = {
  name: string;
  logo?: string;
  textColor?: string;
  onClick?: () => void;
};
export const NameCustomField: FC<TNameCustomField> = React.memo(({
  name,
  logo,
  textColor,
  onClick
}) => <NameCustomFieldStyles className={`${onClick ? 'onClick' : ''}`} onClick={onClick} aria-label={name}
// tabIndex={onClick ? 1 : -1}
>
      <div className="customNameWrapper">
        {logo && <div className={logo ? 'imageContainer' : 'imageContainer default'}>
            <div className="image">{logo && <img src={logo} alt="logo" />}</div>
          </div>}
        <span style={{
      color: textColor || '#000'
    }}>{name}</span>
      </div>
    </NameCustomFieldStyles>);