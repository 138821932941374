import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ReviewStyles } from './styles';
import { ApiChatTemplates, reviewApi, usersApi, companyApi, chatApi, chatTemplatesApi, companySettingsApi, ApiCategory, categoryApi, ApiTag, tagsApi, ApiReviewFormAnswer, ApiIssue, ApiNode } from '../../../api';
import ScaleField from '../../../components/clientForm/scaleField/ScaleField';
import QuantityField from '../../../components/clientForm/quantityField/QuantityField';
import { networks, reviewsFilesExtraFieldText, reviewsFilesIcons, reviewsStatusIconsAndText, hiddenReviewStatuses, sentimentIcon } from '../../../constants';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import CustomSelect from '../../../components/form/customSelect/CustomSelect';
import TextArea from '../../../components/form/textArea/TextArea';
import { useAppSelector } from '../../../state';
import { reopenDialogData, FormMessageValues, FormValues, possibleOptions, ReviewData, closeDialogData, messageTextareaStyles, chatTabsEdit, AdditionalReviewData, ExtendedApiReviewFormAnswer, getFileType } from './utilt';
import { format } from 'date-fns';
import { IReviewDiscussionMessage, IContact, IOneReviewForm, IUser } from '../../../entities';
import { CopyIcon, EmojiIcon, FileIcon, KeyIcon, PencilIcon, PinIcon, SaveIcon, SendIcon, SolvedIcon, TemplatesIcon } from '../../../assets';
import { getErrorMessage, handleKeyUp, addFieldPositionToAnswer, getTypeOfContact, addKeyQuestionValueToAnswer } from '../../../utils';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { AuditModal, EditUserModal, ImgModal, Loader, mapApiColorToFieldColor, ModalConfirmDecline, reduceFieldArray, ReviewCategories, ReviewTags, ReviewTickets, SubHeader, TransparentButton } from '../../../components';
import { getTranslationByLangOrEng } from '../../../i18n';
const Review_1 = _compiledBlock(_props => _props.v0, {
  name: "Review_1",
  portals: ["v0"]
});
export const Review = () => {
  const navigate = useNavigate();
  const {
    companyId,
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [initialMessageValues, setInitialMessageValues] = useState<FormMessageValues>({
    text: '',
    close: false
  });
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [reviewData, setReviewData] = useState<ReviewData>();
  const [categories, setCategories] = useState<ApiCategory[]>([]);
  const [tickets, setTickets] = useState<ApiIssue[]>([]);
  const [tags, setTags] = useState<ApiTag[]>([]);
  const [companyCategories, setCompanyCategories] = useState<ApiCategory[]>([]);
  const [companyTags, setCompanyTags] = useState<ApiTag[]>([]);
  const [additionalReviewData, setAdditionalReviewData] = useState<AdditionalReviewData>();
  const [messageSending, setMessageSending] = useState<boolean>(false);
  const [answers, setAnswers] = useState<ApiReviewFormAnswer[]>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [chatError, setChatError] = useState<{
    message: string;
    statusCode: number;
  } | undefined>();
  const [modalErrorIndex, setModalErrorIndex] = useState<number>(0);
  const [isReviewDialogOpened, setIsReviewDialogOpened] = useState<boolean>(true);
  const [apiRequestTimeout, setApiRequestTimeout] = useState();
  const [contactsData, setContactsData] = useState<IContact[]>([]);
  const [isSuccessfullySaved, setIsSuccessfullySaved] = useState<boolean>(false);
  const [node, setNode] = useState<ApiNode | null>(null);
  const [nonSubmitModal, setNonSubmitModal] = useState<boolean>(false);
  const [validStatus, setValidStatus] = useState<number>(initialValues?.status.value);
  const [chatHistory, setChatHistory] = useState<IReviewDiscussionMessage[]>([]);
  const [chatTemplates, setChatTemplates] = useState<ApiChatTemplates[]>();
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [isEmoji, setIsEmoji] = useState<boolean>(false);
  const [imgData, setImgData] = useState<{
    attachmentUrl: string;
    DeleteUrl: string;
    attachmentType: number;
  }>({
    attachmentUrl: '',
    DeleteUrl: '',
    attachmentType: 0
  });
  const [imageModal, setImageModal] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<string>('');
  const [editModal, setEditModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [chatTab, setChatTab] = useState<string>('target');
  const [audit, setAudit] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<number[]>([]);
  const [invizComment, setInvizComment] = useState<boolean>(false);
  const [phoneVerify, setPhoneVerify] = useState<boolean>(false);
  const [ticketEnable, setTicketEnable] = useState<boolean>(false);
  const reviewRef = useRef<IOneReviewForm>();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const form3Ref = useRef<FormikProps<FormMessageValues>>(null);
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const connectionChannelsArr = additionalReviewData?.connectionChannels ? Object.keys(additionalReviewData.connectionChannels) : [];
  const networksTypes = useMemo(() => contactsData.map(contact => getTypeOfContact(contact, connectionChannelsArr[0])), [contactsData]);
  const DisableNetworkIcon = (connectionChannelsArr[0] === 'telegram' || connectionChannelsArr[0] === 'viber') && connectionChannelsArr[0] ? networks[connectionChannelsArr[0]].icon : null;
  const statusChatEnabled = useMemo(() => {
    if (reviewData) {
      return networksTypes.includes('telegram') || networksTypes.includes('viber');
    }
    return false;
  }, [companyId, reviewData, initialMessageValues, networksTypes]);
  const chatEnabled = useMemo(() => {
    if (reviewData) {
      return !!initialMessageValues && !!(networksTypes.includes('telegram') || networksTypes.includes('viber'));
    }
    return false;
  }, [companyId, reviewData, initialMessageValues, networksTypes]);

  // console.log(answers)

  const getReviewInfo = async (id: string) => {
    try {
      const review = await reviewApi.getReview(id);
      if (!review.data.status) {
        await reviewApi.updateReview(+id, {
          status: 1
        });
      }
      setCategories(review.data.categories || []);
      setTags(review.data.tags || []);
      setNode(review.data.node);
      setReviewData({
        reviewId: review.data.id,
        reviewCreatedAt: review.data.createAt,
        wasOverdue: review.data.wasOverdue,
        reviewUpdatedAt: review.data.updatedAt,
        reviewStatus: review.data.status
      });
      const reviewAnswers = review.data.answers.map(item => ({
        ...item,
        type: item.field.type,
        position: +addFieldPositionToAnswer(item),
        keyQuestion: addKeyQuestionValueToAnswer(item)
      })).sort((a, b) => a.position - b.position);
      setAnswers(reviewAnswers.filter(ans => possibleOptions.includes(ans.type)));
      reviewRef.current = review.data;
      const formSource: {
        name: string | number;
        id: number;
      } | null = null;
      const responsibleResponses: string[] = [];
      let responsibleReview: IUser | undefined;
      if (review.data.responsibleID) {
        responsibleReview = await usersApi.getUser(review.data.responsibleID).then(res => res.data);
      }
      const company = await companyApi.getCompanyById(review.data.node.companyID);
      const nameFieldAnswer = reviewAnswers.find(item => item?.type?.includes('name'))?.value;
      const userName = nameFieldAnswer?.includes('|||') ? nameFieldAnswer?.split('|||')[0] : nameFieldAnswer;
      const phoneFieldAnswer = reviewAnswers.find(item => item?.type?.includes('phone' || 'phone_number'))?.value || '';
      const phone: string = phoneFieldAnswer?.includes('|||') ? phoneFieldAnswer?.split('|||')[0] : phoneFieldAnswer;
      const email = reviewAnswers.find(item => item?.type?.includes('email'))?.value?.split('|||')?.[0];
      const contact = reviewAnswers.find(item => item?.type?.includes('connection_channel'));
      const connectionChannels: {
        [key: string]: string;
      } = {};
      const isWithoutAnswer = contact?.value?.includes('without answer');
      if (contact && !isWithoutAnswer) {
        connectionChannels[contact?.value] = phone;
      }
      if (review.data.status >= 2 && review.data.status < 6 || review.data.status === 10) {
        setIsReviewDialogOpened(false);
      }
      setInvizComment(!!review.data.comment);
      setInitialValues({
        status: reviewsStatusIconsAndText(interfaceLanguage)[!review.data.status ? 1 : review.data.status],
        comment: review.data.comment
      });
      setAdditionalReviewData({
        companyId: company.data.id,
        nodeId: review.data.node.id,
        companyName: company.data.name,
        nodeName: review.data.node.name,
        nodeCode: review.data.node.code,
        userName,
        phone,
        email,
        connectionChannels,
        formSource,
        responsible: responsibleResponses,
        responsibleReview,
        sentiment: review.data.sentiment
      });
    } catch (e) {
      console.log(e);
    }
  };
  const getData = async () => {
    setMessageSending(true);
    getReviewInfo(id!);
    const contacts = await reviewApi.getContactsByReviewId(+id!);
    contacts.data.forEach(el => {
      if (el.phoneConfirmed) {
        setPhoneVerify(true);
      }
    });
    if (contacts.statusCode >= 200 && contacts.statusCode < 300) {
      setContactsData(contacts.data);
    }
    setMessageSending(false);
    setInitialMessageValues({
      text: '',
      close: false
    });
  };
  const getMessagesHistoryData = async () => {
    setMessageSending(true);
    const chatHistory = chatTab === 'target' ? await reviewApi.getReviewDiscussionHistory(+id!) : await reviewApi.getReviewAllDiscussionHistory(+id!);
    setChatHistory(chatHistory.statusCode === 200 ? chatHistory.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : []);
    if (chatTab === 'target' && !!chatHistory.data.messages.length) {
      setIsReviewDialogOpened(!!chatHistory.data.clientID);
    }
    setInitialMessageValues({
      text: '',
      close: false
    });
    setMessageSending(false);
  };
  const onClickToUser = async (id: number) => {
    setSelectedUser(id);
    setEditModal(true);
  };
  const fileSelectedHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFileLoading(true);
    if (event.target.files && event.target.files[0]) {
      chatApi.sendChatImg(event.target.files[0]).then(res => {
        setImgData({
          attachmentUrl: res.data.attachmentUrl,
          DeleteUrl: res.data.DeleteUrl,
          attachmentType: res.data.attachmentType
        });
        setFileLoading(false);
      }).catch(e => console.log(e));
    }
  }, []);
  const deleteSelectedFile = async () => {
    const res = await fetch(imgData.DeleteUrl, {
      method: 'DELETE',
      redirect: 'follow',
      cache: 'no-cache'
    });
    if (res.status === 204) {
      setImgData({
        attachmentUrl: '',
        DeleteUrl: '',
        attachmentType: 0
      });
    } else {
      console.log('Error');
    }
  };
  function recursiveTimeout() {
    const interval = setTimeout(() => {
      if (id) {
        getMessagesHistoryData();
        recursiveTimeout();

        // @ts-ignore
        setApiRequestTimeout(interval);
      }
    }, 60000);
  }
  function handleContinueDialog() {
    reviewApi.textToReview(+id!, reopenDialogData).then(async res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getMessagesHistoryData();
        setIsReviewDialogOpened(true);
      } else {
        setErrorMessage('Щось не так. Будь ласка спробуйте пізніше.');
      }
    });
  }
  const onSaveModalConfirm = useCallback((values: FormValues) => {
    const data: {
      comment?: string;
      status?: number;
    } = {};
    if (values.comment.length) {
      data.comment = values.comment;
    }
    if (values.status) {
      data.status = values.status.value;
    }
    reviewApi.updateReview(reviewRef.current!.id, data).then(res => {
      if (!(res.statusCode >= 200 && res.statusCode < 300)) {
        setDataSaveError('* Виникла помилка при збереженні даних');
      } else {
        window.location.reload();
      }
    });
    setIsSuccessfullySaved(true);
  }, []);
  const onDeclineAndCloseSaveModalHandler = () => {
    setConfirmSaveModalOpen(false);
    setIsSuccessfullySaved(false);
  };
  const getComponentByAnswerType = (answer: ExtendedApiReviewFormAnswer) => {
    // console.log(answer)
    switch (answer?.type) {
      case 'checkbox':
      case 'dropdown':
      case 'radio':
        {
          const ansValue = answer.value?.split('|||')[0];
          return <div className="selectableContainer">
            <div className="selectableValue">{ansValue}</div>
          </div>;
        }
      case 'quantity':
        {
          const answerQuantity = answer?.value?.split('|||')[0] || '';
          const quantityButtonSignsColor = answer.field.styles.find(option => option.key === 'quantity_buttons_signs_color')?.value ? mapApiColorToFieldColor(answer.field.styles.find(option => option.key === 'quantity_buttons_signs_color')?.value!).color : '';
          return <div style={{
            width: '100%',
            maxWidth: '400px'
          }}>
            <QuantityField name="quantity" index={1} value={answerQuantity} onChange={() => null} required={false} buttonsBackgroundColor="#000" signsColor={quantityButtonSignsColor as string} extraBlockStyles="extraBlockStyles" quantityCounterContainerExtraStyles="quantityCounterContainerExtraStyles" />
          </div>;
        }
      case 'file_upload':
        {
          const uploadFiles = answer?.value?.split('|||').filter(item => item !== '');
          const uploadFilesWithType = uploadFiles.map(link => {
            const extension = link.slice(link.lastIndexOf('.'));
            const type = getFileType(extension);
            return {
              type,
              fileLink: link
            };
          }).filter(types => ['image', 'document', 'video', 'audio'].includes(types.type));
          return <div className="infoContainer">
            <div className="filesContainer">
              {uploadFilesWithType.map(item => {
                const Icon = reviewsFilesIcons[item.type];
                const url = item.fileLink;
                return <div className="oneFile">
                    {item.type === 'document' ? <a className="fileContainer" href={url} key={url}>
                        <div>
                          <Icon width={16} height={16} color="#000" />
                        </div>
                        <span>
                          {reviewsFilesExtraFieldText(interfaceLanguage)[item.type]}
                        </span>
                      </a> : <div className="fileContainer" style={{
                    cursor: 'pointer'
                  }} onClick={() => window.open(url, 'Data', 'height=500,width=500')} key={url}>
                        <Icon width={16} height={16} color="#000" />
                        <span>
                          {reviewsFilesExtraFieldText(interfaceLanguage)[item.type]}
                        </span>
                      </div>}
                  </div>;
              })}
            </div>
          </div>;
        }
      case 'hidden':
        {
          const ansValue = answer.value?.split('|||')[0];
          return <div className="hiddenContainer">
            <div className="hiddenKey">
              {/* <span>{answer.field.hiddenKey}</span>: */}
            </div>
            <div className="hiddenValue">{ansValue}</div>
          </div>;
        }
      case 'detailed_response':
        {
          const ansValue = answer.value?.split('|||')[0];
          return <div className="detailedContainer">
            <div className="detailedValue">{ansValue}</div>
          </div>;
        }
      case 'nps':
      case 'scale':
      case 'ces':
      case 'csat':
        {
          let colors: any;
          const scaleType: string = answer.field.options.find(el => el.key === 'scale_type')?.value || '';
          const defaultValue: string = answer.field.options.find(el => el.key === 'default_value')?.value || '0';
          const showDefaultValue: boolean = answer.field.options.find(el => el.key === 'show_default_value')?.value === 'true';
          const signatureMin: string = answer.field.options.find(el => el.key === 'signature_min')?.value || '';
          const signatureMax: string = answer.field.options.find(el => el.key === 'signature_max')?.value || '';
          if (scaleType === 'star') {
            const selectedStarColor = answer.field.styles.find(option => option.key === 'selected_star_color')?.value ? mapApiColorToFieldColor(answer.field.styles.find(option => option.key === 'selected_star_color')?.value!).color : '';
            const notSelectedStarBorder = answer.field.styles.find(option => option.key === 'not_selected_star_border')?.value ? mapApiColorToFieldColor(answer.field.styles.find(option => option.key === 'not_selected_star_border')?.value!).color : '';
            const notSelectedStarBackground = answer.field.styles.find(option => option.key === 'not_selected_star_background')?.value ? mapApiColorToFieldColor(answer.field.styles.find(option => option.key === 'not_selected_star_background')?.value!).color : '';
            colors = {
              selectedStarColor: selectedStarColor as string,
              notSelectedStarBorder: notSelectedStarBorder as string,
              notSelectedStarBackground: notSelectedStarBackground as string
            };
          }
          if (scaleType === 'numeric') {
            const numbersColor = answer.field.styles.find(option => option.key === 'numbers_color')?.value ? mapApiColorToFieldColor(answer.field.styles.find(option => option.key === 'numbers_color')?.value!).color : '';
            const selectedButtonColor = answer.field.styles.find(option => option.key === 'selected_button_color')?.value ? mapApiColorToFieldColor(answer.field.styles.find(option => option.key === 'selected_button_color')?.value!).color : '';
            colors = {
              numbersColor: numbersColor as string,
              selectedButtonColor: selectedButtonColor as string
            };
          }
          if (scaleType === 'color') {
            const pointerColor = answer.field.styles.find(option => option.key === 'pointer_color')?.value ? mapApiColorToFieldColor(answer.field.styles.find(option => option.key === 'pointer_color')?.value!).color : '';
            const scaleGradientColors = reduceFieldArray(answer.field.styles, 'main_scale_color_').map(el => {
              const color = el.value.split('|||');
              return {
                color: color[1],
                name: color[0]
              };
            });
            colors = {
              colors: scaleGradientColors.map(el => el.color) || [],
              pointerColor: pointerColor || ''
            };
          }
          return <div className="scaleContainer">
            <ScaleField scaleType={scaleType} colors={colors} maxValues={+answer.value.split('/')[1]} defaultValues={+defaultValue} showDefaultValue={showDefaultValue} signatureMin={signatureMin || 'мін'} signatureMax={signatureMax || 'макс'} index={1} required={false} value={+answer.value.split('/')[0]} handleChange={() => {}} withoutTitle />
          </div>;
        }
    }
  };
  const handleCancel = () => {
    navigate(`/reviews/page/1?companyID=${companyId}`);
  };
  const handleDeleteReview = () => {
    if (id) {
      reviewApi.deleteReview([+id]).then(res => {
        if (res.statusCode === 200) {
          navigate(`/reviews/page/1?companyID=${companyId}`);
        }
      });
    }
  };
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  async function onSubmitManageForm(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    setSubmitting(false);
    if (id) {
      const review = await reviewApi.getReview(id);
      setReviewData({
        reviewId: review.data.id,
        reviewCreatedAt: review.data.createAt,
        wasOverdue: review.data.wasOverdue,
        reviewUpdatedAt: review.data.updatedAt,
        reviewStatus: review.data.status
      });
      setInitialValues({
        status: reviewsStatusIconsAndText(interfaceLanguage)[!review.data.status ? 1 : review.data.status],
        comment: review.data.comment
      });
      if (review.data.status >= 2 && review.data.status < 6 || review.data.status === 10) {
        return;
      }
      setConfirmSaveModalOpen(true);
    }
  }
  async function onSubmitChat(values: FormMessageValues, {
    setSubmitting,
    resetForm
  }: FormikHelpers<FormMessageValues>) {
    setSubmitting(false);
    if (contactsData.length && id && (values.text || imgData.attachmentUrl)) {
      const data = {
        text: values.text,
        close: values.close,
        attachmentUrl: imgData.attachmentUrl
      };
      setErrorMessage('');
      setMessageSending(true);
      await reviewApi.textToReview(+id, data).then(async res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          resetForm({
            values: {
              text: '',
              close: false
            }
          });
          setImgData({
            attachmentUrl: '',
            DeleteUrl: '',
            attachmentType: 0
          });
          getMessagesHistoryData();
        } else {
          setChatError({
            // @ts-ignore
            message: res.data.message,
            statusCode: res.statusCode
          });
        }
      }).finally(() => {
        setMessageSending(false);
      });
    }
  }
  const renderManageForm = ({
    values,
    errors,
    setFieldValue
  }: FormikProps<FormValues>) => {
    useEffect(() => {
      setFieldValue('status', reviewsStatusIconsAndText(interfaceLanguage)[values.status.value]);
    }, [interfaceLanguage]);
    let selectStatuses = useMemo(() => Object.values(reviewsStatusIconsAndText(interfaceLanguage))?.filter(item => statuses.length ? statuses.filter(status => !networksTypes.includes('viber') && !networksTypes.includes('telegram') && !networksTypes.includes('whatsapp') ? status !== 7 : true).includes(item?.value) : !hiddenReviewStatuses.includes(item?.value)), [statuses, networksTypes]);
    if (+companyId! === 166 && !statusChatEnabled) {
      selectStatuses = selectStatuses.filter(el => el.value !== 7);
    }
    return <Form>
        <CustomSelect label="Статус розгляду" name="status" selectKey="text" placeholder="Вибрати статус" options={selectStatuses || []} disabled={initialValues?.status.value >= 2 && initialValues?.status.value < 6 || initialValues?.status.value === 10} value={values.status} handleSelect={reviewsStatusIconsAndText => {
        setValidStatus(reviewsStatusIconsAndText.value);
        setFieldValue('status', reviewsStatusIconsAndText);
      }} />

        <div className="colapsLabel" onClick={() => setInvizComment(!invizComment)} aria-label="Меню користувача" tabIndex={0} onKeyDown={e => {
        if (e.key === 'Enter') {
          setInvizComment(!invizComment);
        }
      }}>
          <span>Нотатки</span>
          <PencilIcon color="#000" width={12} />
        </div>
        <div className={`colapsWrapper ${invizComment ? 'show' : ''}`}>
          <TextArea disabled={initialValues?.status.value >= 2 && initialValues?.status.value < 6 || initialValues?.status.value === 10} name="comment" onChange={setFieldValue} onKeyUp={() => handleKeyUp('password', setErrorMessage, errorMessage)} placeholder="Введіть коментар" value={values.comment} extraBlockStyles={{
          height: 120
        }} count={false} />
        </div>

        <div className="submitContainer">
          <SubmitButton disabled={initialValues?.status.value >= 2 && initialValues?.status.value < 6 || values.status.value === initialValues?.status.value && values.comment === initialValues?.comment}>
            Зберегти
          </SubmitButton>
        </div>

        {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
      </Form>;
  };
  const renderChatForm = ({
    values,
    setFieldValue
  }: FormikProps<FormMessageValues>) => <Form>
      <div className="chatContainer">
        <div className="chatted">
          {chatTabsEdit(interfaceLanguage).map(tab => <button key={tab.key} onClick={() => setChatTab(tab.key)} className={`chatted-item ${chatTab === tab.key && 'chatted-active'}`} type="button" aria-label={tab.text}>
              {tab.text}
            </button>)}
        </div>

        <div className={isReviewDialogOpened ? 'reviewDiscussionHistory' : 'reviewDiscussionHistory chatIsClosedReviewHistory'} ref={chatHistoryRef}>
          {chatHistory.length > 0 ? <>
              {chatHistory.map((item, index) => <div key={item.id || `message-${index + 1}`} className={`message ${item.author}`}>
                  <p className="messageText">{item.text}</p>
                  {item.attachmentUrl && item.attachmentType === 1 && <img src={item.attachmentUrl} className="chatImg" alt="img" onClick={() => {
              setSelectedImg(item.attachmentUrl!);
              setImageModal(true);
            }} />}

                  {item.attachmentUrl && item.attachmentType === 2 && <a target="_blank" href={item.attachmentUrl} className="chatLink" rel="noreferrer">
                      <SaveIcon color="#000" />
                      <span>Download</span>
                    </a>}
                  <div className="authorAndDate">
                    <p>{item.author}</p>
                    <p>{format(new Date(item.date), 'y-MM-d HH:mm:ss')}</p>
                  </div>
                </div>)}
            </> : <div className="chatHistoryEmptyContainer">
              <h2 className="chatHistoryEmptyTitle">Історія чату порожня</h2>
            </div>}
        </div>
      </div>

      {isReviewDialogOpened ? <div className="messageInputContainer">
          {chatTab === 'target' && <>
              <TextArea name="text" onChange={setFieldValue} setIsTemplate={setIsTemplate} onKeyUp={() => handleKeyUp('text', setErrorMessage, errorMessage)} placeholder="Напишіть своє повідомлення" value={values.text} extraBlockStyles={{
          height: 100
        }} extraTextAreaStyles={messageTextareaStyles} error={typeof errorMessage === 'object' ? getErrorMessage('text', errorMessage) : undefined} />
              <div className="btnWrapper">
                <button className="submitButton" type="submit">
                  {messageSending ? <Loader height={20} margin={0} /> : <SendIcon height={20} color="#000" />}
                </button>
                <button className="submitButton" onClick={() => setIsTemplate(!isTemplate)} type="button" disabled={!chatTemplates?.length}>
                  <TemplatesIcon height={20} color="#000" />
                </button>
                <button className="submitButton" type="button" onClick={() => setIsEmoji(!isEmoji)}>
                  <EmojiIcon height={20} color="#000" />
                </button>
                <label htmlFor="file-input" tabIndex={0} className="fileBtn">
                  {fileLoading ? <Loader height={20} margin={0} /> : <PinIcon height={20} color="#000" />}
                </label>
                <input id="file-input" accept=".png, .jpeg, .jpg, .mp4, .avi, .mov, .mkv" type="file" onChange={fileSelectedHandler} style={{
            display: 'none'
          }} />
              </div>
              {isTemplate && chatTemplates && <div className="chatTemplates">
                  {chatTemplates?.filter(template => template.key.toLocaleLowerCase().includes(values.text.toLocaleLowerCase())).map(template => <div onClick={() => {
            setFieldValue('text', template.content);
            setIsTemplate(false);
          }} className="chatTemplate">
                        <b>{template.key}</b>-{template.content}
                      </div>)}
                </div>}
              {isEmoji && <div className="emojiContainer">
                  <Picker data={data} onEmojiSelect={(e: any) => {
            setFieldValue('text', values.text + e.native);
          }} />
                </div>}
              {imgData.attachmentUrl !== '' && imgData.attachmentType === 1 && <div className="selectedImg">
                  <img src={imgData.attachmentUrl} alt="attachmentUrl" />
                  <button onClick={deleteSelectedFile} type="button" className="delBtn">
                    x
                  </button>
                </div>}
              {imgData.attachmentUrl !== '' && imgData.attachmentType === 2 && <div className="selectedFile">
                  <FileIcon width={50} height={50} />
                  <button onClick={deleteSelectedFile} type="button" className="delBtn">
                    x
                  </button>
                </div>}
            </>}
        </div> : <div className="chatIsFinished">Чат завершено</div>}

      <div className="closeReviewDialogContainer">
        {isReviewDialogOpened ? <TransparentButton text="Завершити діалог" handleClick={() => setIsModalOpened(true)} filledColor="rgb(255, 1, 1)" /> :
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
            {!(initialValues?.status.value >= 2 && initialValues?.status.value < 6 || initialValues?.status.value === 10) && <TransparentButton disabled={initialValues?.status.value >= 2 && initialValues?.status.value < 6} handleClick={handleContinueDialog} filled text="Продовжити діалог" />}
          </>}
      </div>

      {typeof errorMessage === 'string' && <p className="extraErrorMessage">{errorMessage}</p>}
    </Form>;
  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        const companySettings = await companySettingsApi.getCompanySettings(+companyId!);
        const clientStatusChanges = companySettings.data['client-status-changes'];
        const clientStatusEmptyChat = companySettings.data['client-statuses-when-chat-is-empty'];
        setTicketEnable(!!companySettings.data['tickets-enabled']);
        if (clientStatusChanges) {
          const statusesSource = clientStatusChanges.find(source => source.sourceID === additionalReviewData?.formSource?.id);
          if (statusesSource) {
            const statusesChanges = statusesSource.changes;
            let apiStatuses = reviewData?.reviewStatus ? statusesChanges[reviewData?.reviewStatus] : statusesChanges[1];
            if (!statusChatEnabled) {
              apiStatuses = apiStatuses.filter(el => el !== 7);
            }
            setStatuses(apiStatuses);
          }
        } else {
          setStatuses([]);
        }
        if (clientStatusEmptyChat && chatHistory.length === 0) {
          setStatuses(clientStatusEmptyChat);
        }
        if (!clientStatusChanges && !statusChatEnabled) {
          setStatuses(prev => prev.filter(el => el !== 7));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchSettingsData();
  }, [companyId, reviewData, chatHistory, categories, tags, additionalReviewData, statusChatEnabled]);
  useEffect(() => {
    getMessagesHistoryData();
  }, [chatTab]);
  useEffect(() => {
    try {
      if (id && companyId) {
        getData();
        chatTemplatesApi.getChatTemplates(parseInt(companyId!, 10)).then(res => {
          if (res.statusCode === 200) {
            setChatTemplates(res.data);
          } else {
            console.log(res.data);
          }
        });
        categoryApi.getCategoriesByCompany(+companyId).then(res => {
          setCompanyCategories(res.data || []);
        });
        tagsApi.getCompanyTags(+companyId).then(res => {
          setCompanyTags(res.data || []);
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }, []);
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistoryRef.current, chatHistory]);
  useEffect(() => {
    if (contactsData.length > 0) {
      if (isReviewDialogOpened) {
        // recursiveTimeout();

        return () => {
          clearTimeout(apiRequestTimeout);
        };
      }
      if (!isReviewDialogOpened) {
        clearTimeout(apiRequestTimeout);
      }
    }
  }, [isReviewDialogOpened, contactsData]);
  useEffect(() => {
    setValidStatus(initialValues?.status.value);
  }, [initialValues?.status.value]);
  return /*@million jsx-skip*/<Review_1 v0={<ReviewStyles>
      <Helmet>
        <title>{`${id} Review Voicer`}</title>
      </Helmet>
      {!reviewData && <div className="loaderReviewPageContainer">
          <Loader />
        </div>}

      {reviewData && <>
          <SubHeader title={`Відгук: №${id}`} pathArray={[{
        name: additionalReviewData?.companyName || ''
      }, {
        name: additionalReviewData?.nodeCode || ''
      }]}>
            <div className="subBtns" style={{
          display: 'flex'
        }}>
              <TransparentButton text="Видалити" handleClick={handleDeleteReview} filledColor="#e50000" />
              <TransparentButton text="Історія опрацювання" handleClick={() => setAudit(true)} filled />
              <TransparentButton disabled={!(initialValues?.status.value === validStatus)} text="Закрити" handleClick={handleCancel} filled={initialValues?.status.value === validStatus} />
            </div>
          </SubHeader>

          <div className="mainWrapper">
            <div className="answersWrapper">
              <h3>
                {getTranslationByLangOrEng(interfaceLanguage, 'detailed_feedback_information')}
              </h3>
              <div className="dashed-line" />
              <div className="reviewInfo">
                {reviewData?.reviewId && <div className="reviewInfo-item">
                    <span>Ідентифікатор відгуку</span>
                    <span>{reviewData.reviewId}</span>
                  </div>}
                {reviewData?.reviewCreatedAt && <div className="reviewInfo-item">
                    <span>Дата та час створення відгуку</span>
                    <span>{reviewData.reviewCreatedAt}</span>
                  </div>}
                {reviewData?.reviewUpdatedAt && <div className="reviewInfo-item">
                    <span>Дата обробки</span>
                    <span>{reviewData.reviewUpdatedAt}</span>
                  </div>}
                {additionalReviewData?.nodeName && <div className="reviewInfo-item">
                    <span>Фронт-офіс</span>
                    <span>{additionalReviewData.nodeName}</span>
                  </div>}
                {additionalReviewData?.formSource && <div className="reviewInfo-item">
                    <span>Канал коммунікації</span>
                    <span>{additionalReviewData?.formSource.name}</span>
                  </div>}
                {!!additionalReviewData?.responsible.length && +companyId! !== 136 && <div className="reviewInfo-item">
                      <span>Відповідальний</span>
                      <div className="responsibles">
                        {additionalReviewData?.responsible.map(name => <span key={name}>{name}</span>)}
                      </div>
                    </div>}
                {additionalReviewData?.responsibleReview && <div className="reviewInfo-item">
                    <span>Відповідальний за відгук</span>
                    <span onClick={() => onClickToUser(additionalReviewData?.responsibleReview?.id!)} className="btn"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0} aria-label={additionalReviewData?.responsibleReview.name} onKeyDown={e => {
                if (e.key === 'Enter') {
                  onClickToUser(additionalReviewData?.responsibleReview?.id!);
                }
              }}>
                      {additionalReviewData?.responsibleReview.name}
                    </span>
                  </div>}
                {additionalReviewData?.averageGoogleScoring && <div className="reviewInfo-item">
                    <span>Google score for target node</span>
                    <span>{additionalReviewData?.averageGoogleScoring}</span>
                  </div>}
                {additionalReviewData?.sentiment && <div className="reviewInfo-item">
                    {(() => {
                const Icon = sentimentIcon(additionalReviewData.sentiment.sentiment);
                return <>
                          <span>Review sentiment</span>
                          <span>
                            <Icon width={35} height={35} />
                          </span>
                        </>;
              })()}
                  </div>}
              </div>

              <div className="dashed-line" />

              <div className="reviewInfo">
                {additionalReviewData?.userName && <div className="reviewInfo-item">
                    <span>Імʼя</span>
                    <div className="userContainer">
                      {!!contactsData.length && contactsData[0].client_photo && <img src={contactsData[0].client_photo} alt="avatar" />}
                      <span>{additionalReviewData?.userName}</span>
                    </div>
                  </div>}
                {additionalReviewData?.phone && <div className="reviewInfo-item">
                    <span>Телефон</span>
                    <div className="userContainer">
                      <a href={`tel:+${additionalReviewData?.phone}`}>
                        <span>{`+${additionalReviewData?.phone}`}</span>
                      </a>
                      {phoneVerify && <SolvedIcon />}
                      <div className="copyBtn" title="Copy" onClick={() => copyToClipboard(`+${additionalReviewData?.phone}`)}>
                        <CopyIcon width={20} height={20} />
                      </div>
                    </div>
                  </div>}
                {additionalReviewData?.email && <div className="reviewInfo-item">
                    <span>Email</span>
                    <span>{additionalReviewData?.email}</span>
                  </div>}
                {!!additionalReviewData?.connectionChannels && !!Object.keys(additionalReviewData?.connectionChannels).length && <div className="reviewInfo-item">
                      <span>Канал комунікації:</span>
                      <div className="connectionChannelsContainer">
                        {networksTypes && [...new Set(networksTypes)].slice(0, 1).map(contact => {
                  const NetworkIcon = contact ? networks[contact].icon : null;
                  return <div key={contact} className="networkDataContainer">
                                {NetworkIcon && <NetworkIcon width={32} height={28} color={networks[contact].color} key={contact} />}
                              </div>;
                })}
                        {!networksTypes.includes(connectionChannelsArr[0]) && DisableNetworkIcon && <>
                              <DisableNetworkIcon width={32} height={28} color="rgb(204, 204, 204)" />
                              <span>Чат не активовано</span>
                            </>}
                      </div>
                    </div>}
              </div>

              <div className="dashed-line" />

              <h3>Питання та відповіді</h3>
              <div className="dashed-line" />
              <div className="answersContainer">
                {!!answers?.length
            // eslint-disable-next-line arrow-body-style
            && answers.map((answer, index) => {
              // console.log(answer)
              return <div className="answerContainer" key={answer.id}>
                        <div className="answerLabel">
                          <h4>
                            {index + 1 < 10 ? `0${index + 1}` : index + 1}.{' '}
                            {answer.field.question}
                          </h4>
                          {answer.field.options.find(el => el.key === 'key_question')?.value === 'true' && <div>
                              <KeyIcon width={20} height={20} color="#fae087" />
                            </div>}
                        </div>

                        {answer.type && <div>
                            {getComponentByAnswerType(answer as ExtendedApiReviewFormAnswer)}
                          </div>}
                      </div>;
            })}
              </div>
            </div>

            <div className="mainManagerForm">
              <h3>Опрацювання відгуку</h3>

              <div className="dashed-line" />

              {initialValues && <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmitManageForm} enableReinitialize>
                  {renderManageForm}
                </Formik>}

              {!!companyTags.length && <>
                  <h3>Теги</h3>
                  <div className="dashed-line" />

                  {!!id && !!companyId && <ReviewTags reviewID={+id} companyID={+companyId} tags={tags} setTags={setTags} />}
                </>}

              {!!companyCategories.length && <>
                  <h3>Категорія відгуку</h3>
                  <div className="dashed-line" />

                  {!!id && !!companyId && <ReviewCategories reviewID={+id} companyID={+companyId} categories={categories} setCategories={setCategories} />}
                </>}

              {true && <>
                  <h3>Делегування</h3>
                  <div className="dashed-line" />

                  {!!id && !!companyId && !!node && <ReviewTickets reviewID={+id} companyID={+companyId} node={node} setTickets={setTickets} tickets={tickets} />}
                </>}
            </div>

            {chatEnabled && <div className="mainChatTickets">
                <h3>Чат із суб’єктом звернення</h3>
                <div className="dashed-line" />

                <Formik innerRef={form3Ref} initialValues={initialMessageValues} enableReinitialize onSubmit={onSubmitChat}>
                  {renderChatForm}
                </Formik>
              </div>}
          </div>
        </>}

      {confirmSaveModalOpen && <ModalConfirmDecline onClose={onDeclineAndCloseSaveModalHandler} onConfirm={() => onSaveModalConfirm(formRef.current!.values)} onDecline={onDeclineAndCloseSaveModalHandler} confirmText={id ? 'Зберегти зміни' : 'Створити'} declineText="Закрити" title={!isSuccessfullySaved ? 'Ви впевнені що хочете редагувати відгук?' : 'Зміни успішно збережено'} error={!!dataSaveError} errorText={dataSaveError} firstButtonStyles={{
      background: isSuccessfullySaved ? '#000' : '#ff0000'
    }} secondButtonStyles={{
      background: '#000'
    }} isConfirmButtonShown={!isSuccessfullySaved} />}

      {nonSubmitModal && <ModalConfirmDecline onClose={() => setNonSubmitModal(false)} onDecline={() => setNonSubmitModal(false)} title="Ви не зберегли зміни" firstButtonStyles={{
      backgroundColor: '#FF0101',
      border: '1px solid #FF0101'
    }} buttonsLabel="Будь ласка поверніться та збережіть зміни перед закриттям" declineText="Закрити" />}

      {imageModal && <ImgModal onClose={() => setImageModal(false)} url={selectedImg} />}

      {isModalOpened && <ModalConfirmDecline onClose={() => setIsModalOpened(false)} onDecline={() => setIsModalOpened(false)} onConfirm={() => {
      reviewApi.textToReview(+id!, closeDialogData).then(res => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setIsReviewDialogOpened(false);
          setIsModalOpened(false);
        } else {
          setModalErrorIndex(1);
        }
      });
    }} title="Завершити діалог" buttonsLabel="Ви впевнені що хочете завершити діалог?" confirmText="Завершити діалог" declineText="Закрити" firstButtonStyles={{
      border: '1px solid #C4C4C4',
      backgroundColor: '#ffffff',
      color: '#000000'
    }} secondButtonStyles={{
      backgroundColor: '#FF0101',
      border: '1px solid #FF0101'
    }} error={modalErrorIndex === 1} errorText="Видалити" />}

      {audit && <AuditModal onClose={() => setAudit(false)} id={id!} companyID={+companyId!} />}

      {chatError && <ModalConfirmDecline onClose={() => {
      setChatError(undefined);
    }} onDecline={() => {
      setChatError(undefined);
    }} title="Помилка" declineText="Закрити" firstButtonStyles={{
      border: '1px solid #000',
      backgroundColor: '#ffffff',
      color: '#000'
    }} extraStyles={{
      width: '100%',
      maxWidth: 500
    }} buttonsWrapperStyles={{
      display: 'flex',
      justifyContent: 'center'
    }}>
          <div className="chatErrorModalContent">
            <p>Під час надсилання повідомлення сталася помилка</p>
            <div className="errorDetails">
              <p>
                Чат з користувачем не знайдено можливо він не підключився або
                видалив бота
              </p>
            </div>
          </div>
        </ModalConfirmDecline>}
      {editModal && selectedUser && <EditUserModal onClose={() => setEditModal(false)} userId={selectedUser} />}
    </ReviewStyles>} />;
};