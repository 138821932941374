import React, { FC, useEffect } from 'react';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { DataItem } from '../../../../pages';
type THorizontalBar = {
  width: number;
  height: number;
  data: DataItem[];
  hideAxis?: boolean;
};
export const HorizontalBar: FC<THorizontalBar> = ({
  width,
  height,
  data,
  hideAxis
}) => {
  const margin = {
    top: 20,
    right: 20,
    bottom: 60,
    left: 150
  };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const xScale = scaleLinear<number>({
    domain: [0, Math.max(...data.map(d => Math.abs(d.value)))]
  }).range([0, xMax]);
  const yScale = scaleBand<string>({
    domain: data.map(d => d.label),
    padding: 0.2
  }).rangeRound([0, yMax]);
  return <svg width={width} height={height}>
      <Group top={20} left={150}>
        {!hideAxis && <GridColumns scale={xScale} height={yMax} stroke="#000" tickValues={[25, 50, 75]} />}
        <AxisLeft scale={yScale} tickLength={10} numTicks={data.length} tickLabelProps={() => ({
        fontSize: 12,
        fill: 'black',
        textAnchor: 'end',
        verticalAnchor: 'middle',
        width: 80
      })} />
        {data.map((d, i) => <Group key={`bar-${i}`}>
            <rect x={0} y={yScale(d.label)!} width={xScale(Math.abs(d.value))} height={yScale.bandwidth()} fill={d.fill || '#FFDB4D'} />
            <text x={xScale(Math.abs(d.value)) + 5} y={yScale(d.label)! + yScale.bandwidth() / 2} dy=".35em" fontSize={12} fill="black" textAnchor="start">
              {d.count ? d.count : d.value.toFixed(2)}
            </text>
          </Group>)}
        {!hideAxis && <AxisBottom top={yMax} scale={xScale} tickLabelProps={() => ({
        fontSize: 12,
        dy: '1.8em',
        fill: 'black',
        textAnchor: 'middle',
        width: 50
      })} />}
      </Group>
    </svg>;
};