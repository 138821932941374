import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Link, useLocation, useNavigate, useParams, useRoutes, useSearchParams } from 'react-router-dom';
import { LoginStyles, NetworkSelector, NetworkSelectorItem } from './styles';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { Dropdown } from '../../components/molecules/dropdown';
import { formApi } from '../../api';
import { DropdownCheckboxSelector } from '../../components/molecules/dropdownCheckboxSelector';
import { InputField, Loader } from '../../components';
import { DeepArrayItem } from '../../entities';
import { NodeFilterItem } from '../../components/molecules/reviewsTableFilters/nodeFilter/additionalSettings';
import { getErrorMessage, getNodeNamesArray, getNodesDeepArray, groupItemsByDeepLevel, handleKeyUp } from '../../utils';
import { getAllSelectedNodeIds, getParentId, markAllNodesWithAllSelectedChildren } from '../../components/molecules/reviewsTableFilters/nodeFilter/utils';
import { accessRequest } from '../../state/thunk/accessesThunk';
const companyTypes = {
  'diia-tsnap': 'ЦНАП',
  'diia-snap': 'СНАП'
};
interface FormValues {
  companyType: any | undefined;
  position: any | undefined;
  type: any | undefined;
  organisation: any | undefined;
  nodes: any[];
  position_string: string | undefined;
  0: any | undefined;
  1: any | undefined;
  2: any | undefined;
  3: any | undefined;
  4: any | undefined;
}
const initialFormValues: FormValues = {
  companyType: companyTypes['diia-tsnap'],
  position: undefined,
  type: undefined,
  organisation: undefined,
  nodes: [],
  position_string: '',
  0: undefined,
  1: undefined,
  2: undefined,
  3: undefined,
  4: undefined
};
const initialExecPositionValue = [{
  id: 'Executive',
  value: 'Керівник'
}];
const initialWorkersPositionValue = [{
  id: 'Employee_tsnap',
  value: 'Працівник ЦНАП'
}, {
  id: 'Employee_VRM',
  value: 'Працівник ВРМ'
}];
const availableCompanyAliases = ['hsc', 'regdracs', 'diia-tsnap'];
const excludeCompanyAliases = ['diia-tsnap'];
const initialOrganisationValue = {
  'diia-tsnap': [{
    id: 'DTministery',
    value: 'Мінцифри'
  }, {
    id: 'DP_Diia',
    value: 'ДП Дія'
  }, {
    id: 'ODA',
    value: 'ОДА'
  }, {
    id: 'OMS',
    value: 'ОМС'
  }, {
    id: 'TSNAP',
    value: 'ЦНАП'
  }],
  'diia-snap': [{
    id: 'TSNAP',
    value: 'ЦОВВ'
  }, {
    id: 'organisation',
    value: 'структурний підрозділ ЦОВВ/ОДА/ДРАЦС/іншої установи чи організації'
  }, {
    id: 'ZOZ',
    value: 'ЗОЗ'
  }],
  hsc: [{
    id: 'TSNAP',
    value: 'ЦОВВ'
  }, {
    id: 'organisation',
    value: 'структурний підрозділ ЦОВВ/ОДА/ДРАЦС/іншої установи чи організації'
  }, {
    id: 'ZOZ',
    value: 'ЗОЗ'
  }],
  regdracs: [{
    id: 'TSNAP',
    value: 'ЦОВВ'
  }, {
    id: 'organisation',
    value: 'структурний підрозділ ЦОВВ/ОДА/ДРАЦС/іншої установи чи організації'
  }, {
    id: 'ZOZ',
    value: 'ЗОЗ'
  }]
};
const initialPositionValue = {
  DTministery: initialExecPositionValue,
  DP_Diia: initialExecPositionValue,
  ODA: initialExecPositionValue,
  default: [...initialExecPositionValue, ...initialWorkersPositionValue]
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const types = {
  0: 'Країна',
  1: 'Область',
  2: 'Район',
  3: 'Орган що утворив',
  4: 'Фронт-офіс'
};
export const SelectRole = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useQuery();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    data,
    status,
    statusCode
  } = useAppSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const filterParams: {
    nodes?: any[];
  } = {
    nodes: []
  };
  const [initialValues, setInitialValues] = useState<FormValues>(initialFormValues);
  const nodesRef = useRef<DeepArrayItem[]>([]);
  const nodeNamesRef = useRef<NodeFilterItem[]>([]);
  const [loadingDataByCompany, setLoadingDataByCompany] = useState<boolean>(false);
  const [initialCompanyVariants, setInitialCompanyVariants] = useState<{
    value: string;
    id: number;
    alias?: string;
  }[]>([]);
  const [initialNodesValues, setInitialNodesValues] = useState<Map<number, {
    id: number;
    value: string;
    parentId?: number;
  }[]>>();
  const nodesLevelToExclude: {
    [key: string]: number[];
  } = {
    DTministery: [1, 2, 3, 4],
    DP_Diia: [1, 2, 3, 4],
    ODA: [2, 3, 4],
    OMS: [],
    TSNAP: []
  };
  useEffect(() => {
    if (status === 'failed') {
      setErrorMessage('Email or password is wrong!');
    }
    setIsLoading(false);
  }, [status, statusCode, data]);
  const validationSchema = Yup.object({
    // companyType: Yup.string().required('Оберіть субʼєкт моніторингу'),
    position: Yup.object().nullable().required('Оберіть посаду'),
    type: Yup.object().nullable().required('Оберіть субʼєкт моніторингу'),
    organisation: Yup.object().required('Оберіть організацію'),
    // nodes: [],
    position_string: Yup.string().required('Зазначте назву посади'),
    // 0: Yup.object().nullable().required('0'),
    1: Yup.object().nullable().when('organisation', {
      is: (organisation: {
        id: string;
      }) => organisation && ['DTministery', 'DP_Diia'].includes(organisation.id),
      then: Yup.object().nullable().notRequired(),
      otherwise: Yup.object().nullable().required('Оберіть область')
    }),
    2: Yup.object().nullable().when('organisation', {
      is: (organisation: {
        id: string;
      }) => organisation && ['DTministery', 'DP_Diia'].includes(organisation.id),
      then: Yup.object().nullable().notRequired(),
      otherwise: Yup.object().nullable().when('organisation', {
        is: (organisation: {
          id: string;
        }) => organisation && ['ODA'].includes(organisation.id),
        then: Yup.object().nullable().notRequired(),
        otherwise: Yup.object().nullable().required('Оберіть район')
      })
    }),
    3: Yup.object().nullable().when('organisation', {
      is: (organisation: {
        id: string;
      }) => organisation && ['DTministery', 'DP_Diia'].includes(organisation.id),
      then: Yup.object().nullable().notRequired(),
      otherwise: Yup.object().nullable().when('organisation', {
        is: (organisation: {
          id: string;
        }) => organisation && ['ODA'].includes(organisation.id),
        then: Yup.object().nullable().notRequired(),
        otherwise: Yup.object().nullable().required('Оберіть орган що утворив ')
      })
    }),
    4: Yup.array().nullable().when('organisation', {
      is: (organisation: {
        id: string;
      }) => organisation && ['DTministery', 'DP_Diia'].includes(organisation.id),
      then: Yup.array().nullable().notRequired(),
      otherwise: Yup.array().nullable().when('organisation', {
        is: (organisation: {
          id: string;
        }) => organisation && ['ODA'].includes(organisation.id),
        then: Yup.array().nullable().notRequired(),
        otherwise: Yup.array().nullable().test('array-has-selected-item', 'Оберіть фронт офіс ', items => !!items?.filter(e => e.value)?.length)
      })
    })
  });
  function getSelectedIds(items: DeepArrayItem[]): number[] {
    let selectedIds: number[] = [];
    for (const item of items) {
      if (item.selected) {
        selectedIds.push(item.id);
      }
      if (item.children && item.children.length > 0) {
        selectedIds = selectedIds.concat(getSelectedIds(item.children));
      }
    }
    return selectedIds;
  }
  async function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    try {
      const params: {
        [key: string]: number | string | any;
      } = {};
      const selectedNodeIds: number[] = [];
      if (values.nodes) {
        getAllSelectedNodeIds(selectedNodeIds, values.nodes);
      }
      if (selectedNodeIds.length > 0) {
        params.nodes = selectedNodeIds;
      } else if (filterParams?.nodes && selectedNodeIds.length === 0) {
        delete filterParams.nodes;
      }
      const resultParams: {
        [key: string]: any;
      } = {};
      Object.entries({
        ...filterParams,
        ...params
      }).forEach(item => {
        if (!!item[0] && item[1] !== undefined && item[1] !== undefined // @ts-ignore
        && item[1] !== '') {
          resultParams[item[0]] = item[1];
        }
      });
      console.log('submits');
      dispatch(accessRequest({
        company: values.type.value,
        organization: values.organisation.value,
        userType: values.position.value,
        position: values.position_string ?? '',
        // посада // посада
        companyID: +values.type.id!,
        nodesIDs: [values[1]?.id, values[2]?.id, values[3]?.id, ...(values[4] ?? []).filter((e: any) => e.value).map((e: any) => e.id)].filter(e => e),
        userID: data?.id!
      }));
      navigate('/auth/thank_you_page');
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    setIsLoading(true);
    formApi.getCompanies().then(e => {
      const availableCompanies = e.data.map(e => ({
        ...e,
        value: e.name,
        alias: e.alias
      })).filter(e => e.alias && availableCompanyAliases.includes(e?.alias));
      setInitialCompanyVariants(availableCompanies);
      const tsnap = availableCompanies.find(e => e.alias === 'diia-tsnap');
      setTimeout(() => {
        formRef.current?.setFieldValue('type', {
          id: tsnap!.id,
          // @ts-ignore
          value: tsnap!.name,
          alias: tsnap!.alias
        });
      }, 100);
      setIsLoading(false);
      if (availableCompanies.length === 1) {
        formRef.current?.setFieldValue('type', {
          id: availableCompanies[0].id,
          alias: availableCompanies[0].alias,
          value: availableCompanies[0].name
        });
      }
    });
  }, []);
  async function getNodesBySelectedCompany(companyId: number) {
    setLoadingDataByCompany(true);
    const nodes = await formApi.getCompanyNodesV2(companyId);
    if (nodes.statusCode >= 200 && nodes.statusCode < 300) {
      const nodeNamesArray: {
        id: number;
        name: string;
        deep: number;
        parentId: number | undefined;
      }[] = [];
      getNodeNamesArray(nodeNamesArray, nodes.data || []);
      nodeNamesArray.map((item, index) => {
        if (item.deep > 0) {
          item.parentId = getParentId(nodeNamesArray, item.deep, index);
        }
        return item;
      });
      nodeNamesRef.current = nodeNamesArray.map(item => ({
        ...item,
        selected: false,
        hasSelectedChildren: false,
        allChildrenAreSelected: false
      }));
      nodesRef.current = getNodesDeepArray(nodeNamesArray);
    }
    setLoadingDataByCompany(false);
  }
  const fetchNodes = async (companyID: string) => {
    console.log(companyID);
    if (companyID) {
      getNodesBySelectedCompany(+(companyID ?? 0)).then(res => {
        const nodesArray = filterParams?.nodes && filterParams.nodes.length > 0 // @ts-ignore
        ? nodeNamesRef.current?.map(node => filterParams?.nodes?.includes(node?.id) ? {
          ...node,
          selected: true
        } : node) : nodeNamesRef.current;
        function findParentNode(array: NodeFilterItem[], item: NodeFilterItem) {
          const parentNodeIndex = array.findIndex(node => node.id === item.parentId);
          if (parentNodeIndex !== undefined) {
            array[parentNodeIndex].hasSelectedChildren = true;
            if (array[parentNodeIndex].deep !== 0 && !array[parentNodeIndex - 1].hasSelectedChildren) {
              findParentNode(array, array[parentNodeIndex]);
            }
          }
        }
        nodesArray.forEach(item => {
          if (item.selected && item.deep !== 0) {
            findParentNode(nodesArray, item);
          }
        });
        const deepNodes = getNodesDeepArray(nodesArray);
        markAllNodesWithAllSelectedChildren(deepNodes);
        const groupedNodes = groupItemsByDeepLevel(deepNodes);
        setInitialNodesValues(groupedNodes);
        formRef.current?.setFieldValue('0', groupedNodes.get(0)?.[0]);
        console.log(groupedNodes);
        formRef.current?.setFieldValue('4', groupedNodes.get(4)?.map(e => ({
          ...e,
          name: e.value,
          value: false
        })));
        formRef.current?.setFieldValue('nodes', deepNodes);
      });
    }
  };
  useEffect(() => {
    fetchNodes('169');
  }, []);
  const excludeLevel = (organizationId: string) => nodesLevelToExclude[organizationId];
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    isValid
  }: FormikProps<FormValues>) => {
    const showPositionField = values?.organisation?.id && !['DTministery', 'DP_Diia', 'ODA'].includes(values?.organisation?.id);
    console.log(values);
    return <Form autoComplete="off">
        {!!initialCompanyVariants.length && <NetworkSelector>
            <NetworkSelectorItem onClick={() => {
          const tsnap = initialCompanyVariants.find(e => e.alias === 'diia-tsnap');
          setFieldValue('companyType', companyTypes['diia-tsnap']);
          setFieldValue('type', {
            id: tsnap!.id,
            // @ts-ignore
            value: tsnap!.name,
            alias: tsnap!.alias
          });
          setFieldValue('organisation', null);
          setFieldValue('1', null);
          setFieldValue('2', null);
          setFieldValue('3', null);
          setFieldValue('4', null);
          fetchNodes('169');
        }} isSelected={values.companyType === 'ЦНАП'}>
              <p>ЦНАП</p>
            </NetworkSelectorItem>
            <div>
              <p>або</p>
            </div>
            <NetworkSelectorItem onClick={() => {
          setFieldValue('companyType', companyTypes['diia-snap']);
          setFieldValue('type', null);
          setFieldValue('organisation', null);
          setFieldValue('1', null);
          setFieldValue('2', null);
          setFieldValue('3', null);
          setFieldValue('4', null);
        }} isSelected={values.companyType === 'СНАП'}>
              <p>СНАП</p>
            </NetworkSelectorItem>
          </NetworkSelector>}
        {values.companyType === 'СНАП' && <Dropdown error={errors.type as string} extraStyles={{
        marginBottom: 10
      }} id="2739h3rh973r2" placeholder="Суб’єкт моніторингу" value={values.type} variants={initialCompanyVariants.filter(e => !excludeCompanyAliases.includes(e.alias ?? ''))} setValue={val => {
        setFieldValue('type', {
          id: val.id,
          value: val.name,
          alias: val.alias
        });
        setFieldValue('organisation', undefined);
        setFieldValue('position', undefined);
        fetchNodes(val.id);
      }} />}
        {/* <Dropdown
          extraStyles={{ marginBottom: 20 }}
          id="2739h3rh973r2"
          placeholder="ЦНАП/СНАП"
          value={values.type}
          variants={initialTypeValue}
          setValue={(val) => {
            console.log(val);
            setFieldValue('type', val);
            setFieldValue('organisation', undefined);
            setFieldValue('position', undefined);
          }}
         /> */}
        {/* @ts-ignore */}
        {values?.type?.id && <Dropdown error={errors.organisation as string} extraStyles={{
        marginBottom: 20
      }} id="2739h3r38712h973r2" placeholder="Організація" value={values.organisation}
      // @ts-ignore
      variants={initialOrganisationValue[values?.type?.alias]} setValue={e => {
        setFieldValue('organisation', e);
        setFieldValue('1', null);
        setFieldValue('2', null);
        setFieldValue('3', null);
        if (values[4]?.length) {
          setFieldValue('4', values[4]?.map((e: any) => ({
            ...e,
            value: false
          })));
        }
        // @ts-ignore
        if (initialPositionValue[e?.id]?.length) {
          setFieldValue('position',
          // @ts-ignore
          initialPositionValue[e.id][0]);
        } else {
          setFieldValue('position',
          // @ts-ignore
          null);
        }
      }} />}
        {/* @ts-ignore */}
        {showPositionField && <Dropdown error={errors.position as string} extraStyles={{
        marginBottom: 20
      }} id="r37hgbf9g374hf34" placeholder="Посада" value={values.position} variants={
      // @ts-ignore
      initialPositionValue[values.organisation.id] ?? initialPositionValue.default} setValue={e => setFieldValue('position', e)} />}
        {values.position?.id && Object.keys(types).filter(e => +e !== 0).map((type: string) => {
        if (!excludeLevel(values.organisation.id).includes(+type) // @ts-ignore
        && (values[+type - 1] || +type === 0)) {
          if (+type === 4) {
            return <DropdownCheckboxSelector
            // @ts-ignore
            error={errors[type]} extraStyles={{
              marginBottom: 15
            }} id={`type-${type}-id`} placeholder={`${types[+type as 0]}`} value={values[+type as 0]} variants={values[+type as 4]?.filter((variant: {
              parentId: any;
            }): boolean => variant.parentId === values[+type - 1 as 0]!.id) ?? []} setValue={(key, value) => {
              const match = key.match(/\[\d+\]\[(\d+)\]/);
              // Extract the value if the match exists
              const result = match ? match[1] : null;
              if (result) {
                // @ts-ignore
                const id = values[4]?.filter((variant: {
                  parentId: any;
                }): boolean => variant.parentId === values[+type - 1 as 0]!.id)[+result as number].id;
                const index = values[4]?.findIndex((e: {
                  id: string;
                }) => e.id === id);
                setFieldValue(`[4][${index}].value`, value);
              }
            }} />;
          }
          return <Dropdown extraStyles={{
            marginBottom: 15
          }} id={`type-${type}-id`} placeholder={types[+type as 0]} value={values[+type as 0]} variants={initialNodesValues?.get(+type)?.filter(variant => variant.parentId === values[+type - 1 as 0]?.id) ?? []} setValue={val => {
            setFieldValue(type, val);
          }} />;
        }
        return null;
      })}
        <div className="nodes">
          {values[4]?.filter((e: any) => e.value).map((e: any) => <div data-tooltip={e.name} className="selectedNode">
                {e.name}
              </div>)}
        </div>
        <InputField name="position_string" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Зазначте назву посади"
      // autocomplete
      value={values.position_string} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
        <div className="row">
          <SubmitButton type="button" onClick={() => navigate(-1)} extraButtonStyles={{
          width: 220
        }} extraBlockStyles={{
          display: 'flex',
          alignSelf: 'center'
        }}>
            Назад
          </SubmitButton>
          <SubmitButton extraButtonStyles={{
          width: 220
        }} extraBlockStyles={{
          display: 'flex',
          alignSelf: 'center'
        }}>
            Підтвердити дані
          </SubmitButton>
        </div>
      </Form>;
  };
  return <LoginStyles>
      <Helmet>
        <title>Authorization Voicer</title>
      </Helmet>
      <div className="formWrapper">
        <div className="formContainer">
          <div className="formTitle">
            <h2>Реєстрація</h2>
            <h3>Оберіть мережу</h3>
          </div>
          {isLoading ? <Loader /> : <Formik validateOnChange innerRef={formRef} enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {renderForm}
            </Formik>}
        </div>
      </div>
    </LoginStyles>;
};