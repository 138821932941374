import styled from 'styled-components';

export const ComplexGeneralDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  align-items: center;

  @media screen and (max-width: 1440px) {
    gap: 10px;
  }

  .rating {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box !important;

    &-col {
      box-sizing: border-box;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1440px) {
        padding: 0;
        font-size: 12px;
      }
    }

    &-item {
      font-size: 14px;

      @media screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
  }

  .chart {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 50px;

    @media screen and (max-width: 1440px) {
      padding: 10px 0 20px;
    }

    &-cards {
      display: flex;
      align-items: center;
      gap: 25px 35px;
      flex-wrap: wrap;
      max-width: 600px;
      flex-shrink: 0;

      @media screen and (max-width: 1440px) {
        gap: 10px;
        max-width: 600px;
      }
    }

    &-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      gap: 8px;
      background: rgb(172, 193, 207);
      box-shadow: 0 4px 8px rgba(172, 193, 207, 0.6);
      width: 180px;
      height: 120px;

      @media screen and (max-width: 1440px) {
        width: 120px;
        height: 80px;
      }

      p {
        font-size: 24px;
        text-align: center;

        @media screen and (max-width: 1440px) {
          font-size: 18px;
        }
      }

      h6 {
        font-size: 18px;
        text-align: center;
        font-weight: 700;

        @media screen and (max-width: 1440px) {
          font-size: 14px;
        }
      }
    }

    &-legend {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 600px;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        @media screen and (max-width: 1440px) {
          font-size: 12px;
        }
      }

      div {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 50px;
    }
  }
`;
