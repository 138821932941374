import { INode } from '../../../entities';
import { getTranslationByLangOrEng } from '../../../i18n';
import { StructTabKeys, TTabStruct } from './TInternal';
export const tabsStructure: (lang: string) => TTabStruct[] = lang => [{
  key: StructTabKeys.structure,
  text: 'Структура'
}, {
  key: StructTabKeys.company_users,
  text: 'Користувачі'
}, {
  key: StructTabKeys.company_forms,
  text: 'Форми'
}, {
  key: StructTabKeys.company_fields,
  text: 'Поля форм'
}];
export const getNodesWithChildsBySearchParams = (arr: Partial<INode>[], searchParams: string) => {
  const arrayCopy = JSON.parse(JSON.stringify(arr));
  return arrayCopy.filter((item: {
    name: string;
    subNodes: string | any[];
  }) => {
    // @ts-ignore
    const found = item.name.toLowerCase().includes(searchParams.toLowerCase());
    if (item.subNodes && item.subNodes.length > 0) {
      item.subNodes = getNodesWithChildsBySearchParams(item.subNodes as Partial<INode>[], searchParams);
    }
    return found || item.subNodes && item.subNodes.length > 0;
  });
};
export const countBorderNumber = (originalArray: [string | number, number][], resultArray: [string, number[]][], item: [number | string, number], index: number) => {
  if (item[1] === 0) {
    resultArray.push([item[0] as string, []]);
  } else if (index === 0) {
    resultArray.push([item[0] as string, [1]]);
  } else {
    if (item[1] === originalArray[index - 1][1]) {
      resultArray.push([item[0] as string, [...resultArray[index - 1][1]]]);
    }
    if (item[1] - originalArray[index - 1][1] === 1) {
      resultArray.push([item[0] as string, Array(resultArray[index - 1][1].length + 1).fill(1)]);
    }
    if (item[1] - originalArray[index - 1][1] > 1) {
      resultArray.push([item[0] as string, [1, ...Array(item[1] - originalArray[index - 1][1] - 1).fill(0), ...resultArray[index - 1][1]]]);
    }
    if (item[1] < originalArray[index - 1][1]) {
      if (originalArray[index - 1][1] === 0) {
        resultArray.push([item[0] as string, [1]]);
      } else {
        const copy = [...resultArray[index - 1][1]];
        copy.splice(1, 1);
        resultArray.push([item[0] as string, copy]);
      }
    }
  }
};