import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataCompleteStyles } from './styles';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { InputField, Loader } from '../../components';
import { authApi } from '../../api';
import { AuthUser } from '../../entities/AuthUser';
import { FormValues } from './types';
import { saveUserCredentials } from '../../state/thunk/authThunk';
import { RegistrationStatuses, useRegistrationStatus } from '../../hooks/useRegistrationStatus';
const initialValues: FormValues = {
  PIB: '',
  INN: '',
  email: '',
  phone: ''
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const UserDataRefill = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useQuery();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    data,
    status,
    statusCode
  } = useAppSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [userStatus, setUserStatus] = useState<number | null>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const {
    setStatus
  } = useRegistrationStatus();
  useEffect(() => {
    if (status === 'failed') {
      setErrorMessage('Email or password is wrong!');
    }
    setIsLoading(false);
  }, [status, statusCode, data]);
  const validationSchema = Yup.object({
    email: Yup.string().email(getTranslationByLangOrEng(interfaceLanguage, 'validation_invalid_email')).required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    phone: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required'))
  });
  function onSubmit(values: FormValues, {
    setSubmitting
  }: FormikHelpers<FormValues>) {
    const id = params.get('user_id');
    if (id) {
      authApi.completeUserData(+id!, {
        email: values.email,
        name: values.PIB,
        drfoCode: values.INN,
        phone: values.phone.includes('+') ? values.phone : `+${values.phone}`
      }).then(e => {
        setStatus(RegistrationStatuses.view_access);
        navigate('/auth/select_role');
      });
    }
  }
  useEffect(() => {
    if (data) {
      setInitialFormValues({
        PIB: data.name,
        INN: data.drfoCode,
        email: data.email,
        phone: data.phone
      });
      setIsLoading(false);
    }
  }, [data, status]);
  useEffect(() => {
    const isToken = localStorage.getItem('access_token');
    console.log(`isToken: ${isToken}`);
    const id = params.get('user_id');
    if (isToken) {
      setIsLoading(true);
      console.log('USER DATA COMPLETE .getUserMe()');
      authApi.getUserMe().then(e => {
        const user: AuthUser = e.data as AuthUser;
        setInitialFormValues({
          PIB: user.name,
          INN: user.drfoCode,
          email: user.email,
          phone: user.phone
        });
        setIsLoading(false);
        setUserStatus(200);
      }).catch(error => console.log(error));
    } else {
      console.log(`set new token: ${params.get('access_token')}`);
      dispatch(saveUserCredentials({
        accessToken: params.get('access_token')!,
        accessTokenExpiresAt: params.get('access_token_expires_at')!,
        refreshToken: params.get('refresh_token')!,
        refreshTokenExpiresAt: params.get('refresh_token_expires_at')!,
        userID: +params.get('user_id')!
      }));
      navigate(`/auth/complete?user_id=${id}`);
    }
  }, []);
  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue
  }: FormikProps<FormValues>) => <Form autoComplete="off">
      {/* <ResponsibleDropdown nodeID={23131} /> */}
      <div className="login-form">
        <InputField disabled name="PIB" onChange={() => {}} onKeyUp={() => handleKeyUp('PIB', setErrorMessage, errorMessage)} placeholder="ПІБ" autocomplete value={values.PIB} />
        <InputField disabled type="text" name="INN" onChange={() => {}} onKeyUp={() => handleKeyUp('INN', setErrorMessage, errorMessage)} placeholder="РНОКПП" autocomplete value={values.INN} />
        <InputField name="email" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Електронна пошта" autocomplete value={values.email} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
        <InputField type="tel" name="phone" onChange={setFieldValue} onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)} placeholder="Номер телефону" autocomplete value={values.phone} error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined} />
      </div>
      <div className="row">
        <SubmitButton type="button" onClick={() => navigate(-1)} extraButtonStyles={{
        width: 220
      }} extraBlockStyles={{
        display: 'flex',
        alignSelf: 'center'
      }}>
          Назад
        </SubmitButton>
        <SubmitButton extraButtonStyles={{
        width: 220
      }} extraBlockStyles={{
        display: 'flex',
        alignSelf: 'center'
      }}>
          Підтвердити дані
        </SubmitButton>
      </div>
    </Form>;
  return <DataCompleteStyles>
      <Helmet>
        <title>Authorization Voicer</title>
      </Helmet>
      <div className="formWrapper">
        <div className="formContainer">
          <h2>Реєстрація</h2>
          {!isLoading ? <Formik enableReinitialize initialValues={initialFormValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {renderForm}
            </Formik> : <Loader />}
        </div>
      </div>
    </DataCompleteStyles>;
};