import { UsersStyles } from './UsersStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import { SearchIcon } from '../../assets';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiResponse, usersApi } from '../../api';
import { IUser, IUserRole } from '../../entities';
import { availablePageSizes } from '../../constants';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { EditButtons, extraFieldsSettings } from './utils';
import { useDebounce } from '../../hooks';
import { EditUserModal, Loader, NameCustomField, SubHeader, Table, TransparentButton } from '../../components';
export const Users = () => {
  const {
    pageNumber
  } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [pageSize, setPageSize] = useState(availablePageSizes[0]);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const debouncedSearch = useDebounce(search, 1000);
  const itemsQuantityRef = useRef<number>(0);
  async function workWithResponse(response: ApiResponse<IUser[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const fullUsers = await response.data.map(async user => {
        const userRoles = await usersApi.getUser(user.id!).then(data => data.data.roles);
        return {
          ...user,
          roles: userRoles
        };
      });
      setUsers(await Promise.all(fullUsers));
      itemsQuantityRef.current = response.count!;
    }
  }
  const onClickToRow = async (data: any) => {
    setSelectedUser(data.id);
    setEditModal(true);
  };
  useEffect(() => {
    setUploading(true);
    usersApi.getFilteredUsers({
      q: debouncedSearch
    }, (pageNumber ? +pageNumber - 1 : 0) * pageSize, pageSize).then(response => {
      workWithResponse(response);
    }).finally(() => {
      setUploading(false);
    });
  }, [pageNumber, pageSize, debouncedSearch]);
  const filterItemsAfterDelete = useCallback((id: number) => {
    setUsers(prev => prev.filter(user => user.id !== id) || []);
  }, [users]);
  useEffect(() => {
    if (users.length) {
      setTableHeaders([{
        Header: 'ID',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.id} />
      }, {
        Header: getTranslationByLangOrEng(interfaceLanguage, 'users_main_table_user_name_header'),
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.name} />
      }, {
        Header: getTranslationByLangOrEng(interfaceLanguage, 'users_main_table_user_email_header'),
        accessor: 'email',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.email} />
      }, {
        Header: 'Посада',
        accessor: 'position',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.position} />
      }, {
        Header: getTranslationByLangOrEng(interfaceLanguage, 'users_main_roles_button'),
        accessor: 'role',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.roles.map((role: {
          name: any;
        }) => `${role.name}`).join(', ')} />
      }, {
        Header: 'Edit buttons',
        accessor: 'editButtons',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <EditButtons id={data.row.original.id} filterItemsAfterDelete={filterItemsAfterDelete} />
      }]);
    }
  }, [users, interfaceLanguage]);
  return <UsersStyles>
      <Helmet>
        <title>Users Voicer</title>
      </Helmet>

      <div style={{
      padding: 15
    }}>
        <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'users_main_title')}>
          <div className="buttonsContainer">
            <TransparentButton handleClick={() => navigate('/users/roles')} text={getTranslationByLangOrEng(interfaceLanguage, 'users_main_roles_button')} filled />
            <TransparentButton handleClick={() => navigate('/users/permissions')} text={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_permissions_button')} filled />
            <TransparentButton handleClick={() => navigate('/users/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'users_main_add_user_button')} filled />
          </div>
        </SubHeader>
        <div className="searchWrapper">
          <div className="search">
            <SearchIcon />
            <input placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')} type="text" value={search} onChange={e => setSearch(e.target.value)} />
          </div>
        </div>

        {uploading && <Loader />}

        {!!users && !!tableHeaders && !uploading && <Table pagination columns={tableHeaders} data={users} hideFieldsSplitters extraFieldsSettings={extraFieldsSettings} headerColumnCounts={{
        name: itemsQuantityRef.current
      }} rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_user_title')} hiddenHeaders={['customField', 'editButtons', 'customFormsField']} fullWidthColumns={['editButtons']} onClickToRow={onClickToRow} pageSize={pageSize} sortableColumns={['id']} setPageSize={setPageSize} currentPage={pageNumber ? +pageNumber : 1} rowsQuantity={itemsQuantityRef.current} pageNumberHandler={page => {
        if (page) {
          navigate(`/users/page/${page}`);
        }
      }} />}
      </div>
      {editModal && selectedUser && <EditUserModal onClose={() => setEditModal(false)} userId={selectedUser} />}
    </UsersStyles>;
};