import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { AnalyticsStartPageStyles } from './styles';
import { TTab } from '../../../pages';
import { ArrowLink } from '../../../assets';
type TAnalyticsStartPage = {
  setSelectedTab: Dispatch<SetStateAction<string>>;
  tabs: TTab[];
  regime: string;
};
export const AnalyticsStartPage: FC<TAnalyticsStartPage> = ({
  setSelectedTab,
  tabs,
  regime
}) => {
  const handleSelect = (tab: string) => {
    setSelectedTab(tab);
  };
  return <AnalyticsStartPageStyles>
      <div className={`links ${regime}`}>
        {tabs.map((el, index) => <div key={el.key} className={`links-item ${regime}`} onClick={() => handleSelect(el.key)}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0} onKeyDown={e => {
        if (e.key === 'Enter') {
          handleSelect(el.key);
        }
      }} aria-label={el.text}>
            <span>{el.text}</span>
          </div>)}
      </div>
    </AnalyticsStartPageStyles>;
};