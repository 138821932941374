import {
  CantDecidedIcon,
  ChatIcon,
  ClosedAutoNoReplyIcon,
  CrossedOutEye,
  DocumentIcon,
  EmailIcon,
  ExpectingReplyIcon,
  InProgressIcon,
  NegativeIcon,
  NeutralIcon,
  NotDecidedIcon,
  OverdueIcon,
  PhoneIcon,
  PhotoWithPlusIcon,
  PlayInCircle,
  PositiveIcon,
  ReopenedIcon,
  ReviewsEyeIcon,
  SolutionNotRequiredIcon,
  SolvedIcon,
  TelegramIcon,
  ViberIcon,
  VideoIcon,
  WhatsAppIcon,
} from './assets';
import { translations } from './i18n';

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const availablePageSizes = [10, 20, 30, 40, 50];
export const hiddenReviewStatuses = [0, 1, 8, 9, 10];
export const hiddenReviewFilterStatuses = [9];

export const availableInterfaceLanguages: { code: string; name: string }[] = [
  {
    name: 'English',
    code: 'EN',
  },
  {
    name: 'Українська',
    code: 'UA',
  },
  {
    name: 'Türk',
    code: 'TR',
  },
  {
    name: 'Română',
    code: 'RO',
  },
  {
    name: 'Қазақ',
    code: 'KZ',
  },
  {
    name: 'Русский',
    code: 'RU',
  },
];

export const networks: { [key: string]: { [key: string]: any } } = {
  call: {
    icon: PhoneIcon,
    color: '#000',
  },
  telegram: {
    icon: TelegramIcon,
    color: '#3C9CE7',
  },
  viber: {
    icon: ViberIcon,
    color: '#7360F2',
  },
  whatsapp: {
    icon: WhatsAppIcon,
    color: 'rgb(43,207,56)',
  },
  sms: {
    icon: ChatIcon,
    color: '#000',
  },
  'without answer': {
    color: '#8c8c8c',
  },
};

export const reviewsFilesExtraFieldText: (lang: string) => {
  [key: string]: string;
} = (lang) => ({
  image: translations[lang].photo,
  document: translations[lang].document,
  video: translations[lang].video,
  audio: translations[lang].record,
});

export const reviewsFilesIcons: { [key: string]: any } = {
  image: PhotoWithPlusIcon,
  document: DocumentIcon,
  video: VideoIcon,
  audio: PlayInCircle,
};

export const reviewsStatusIconsAndText: (lang: string) => {
  [key: string]: { [key: string]: any };
} = (lang) => ({
  // Не переглянуто
  0: {
    icon: CrossedOutEye,
    text: translations[lang].not_viewed,
    value: 0,
  },
  // Переглянуто
  1: {
    icon: ReviewsEyeIcon,
    text: translations[lang].viewed,
    value: 1,
  },
  // Вирішено
  2: {
    icon: SolvedIcon,
    text: translations[lang].solved,
    value: 2,
  },
  // Не вирішено
  3: {
    icon: NotDecidedIcon,
    text: translations[lang].not_decided,
    value: 3,
  },
  // Вирішення неможливе
  4: {
    icon: CantDecidedIcon,
    text: translations[lang].cant_decided,
    value: 4,
  },
  // Вирішення не потрібне
  5: {
    icon: SolutionNotRequiredIcon,
    text: translations[lang].solution_not_required,
    value: 5,
  },
  // В роботі
  6: {
    icon: InProgressIcon,
    text: translations[lang].in_progress,
    value: 6,
  },
  // Очікуємо відповідь
  7: {
    icon: ExpectingReplyIcon,
    text: translations[lang].expecting_reply_from_client,
    value: 7,
  },
  // Прострочено
  8: {
    icon: OverdueIcon,
    text: translations[lang].overdue_status_text,
    value: 8,
  },
  9: {
    icon: ReopenedIcon,
    text: translations[lang].reopened,
    value: 9,
  },
  // Закрито автоматично через відсутність відповіді
  10: {
    icon: ClosedAutoNoReplyIcon,
    text: translations[lang].closed_auto_no_replay,
    value: 10,
  },
});
export const chartColors = [
  '#000',
  '#FFC619',
  '#5388D8',
  '#FF5722',
  '#A6FA1A',
  '#7D5589',
  '#8788BC',
  '#ED3D3F',
  '#9A9397',
  '#473F87',
];

export const errors: {
  [key: string]: { code: number; title: string; description: string };
} = {
  401: {
    code: 401,
    title: 'Unauthorized',
    description: 'Oops! Your request resulted in an error',
  },
  403: {
    code: 404,
    title: 'Bad Request',
    description: 'Oops! Page you are looking for does not exist',
  },
  404: {
    code: 404,
    title: 'Bad Request',
    description: 'Oops! Page you are looking for does not exist',
  },
  500: {
    code: 500,
    title: 'Internal server error',
    description: 'Oops! Sorry something went wrong',
  },
  503: {
    code: 503,
    title: 'Service unavailable',
    description: 'Oops! The server is temporarily busy, try again later!',
  },
};

export const formattedTableColumnsSettings: (lang: string) => {
  [key: string]: { name: string; text: string; value: boolean };
} = (lang) => ({
  reviewId: {
    name: 'reviewId',
    text: translations[lang].table_columns_settings_reviewId_option,
    value: false,
  },
  created_at: {
    name: 'created_at',
    text: translations[lang].table_columns_settings_created_at_option,
    value: false,
  },
  status: {
    name: 'statuses',
    text: translations[lang].table_columns_settings_status_option,
    value: false,
  },
  node: {
    name: 'nodes',
    text: translations[lang].table_columns_settings_node_option,
    value: false,
  },
  source: {
    name: 'sources',
    text: translations[lang].table_columns_settings_source_option,
    value: false,
  },
  keyAnswer: {
    name: 'keyAnswer',
    text: translations[lang].table_columns_settings_estimation_option,
    value: false,
  },
  communication_channel: {
    name: 'communication_channel',
    text: translations[lang]
      .table_columns_settings_communication_channel_option,
    value: false,
  },
  comment: {
    name: 'comment',
    text: translations[lang].table_columns_settings_comment_option,
    value: false,
  },
  name: {
    name: 'name',
    text: translations[lang].table_columns_settings_name_option,
    value: false,
  },
  phone: {
    name: 'phone',
    text: translations[lang].table_columns_settings_phone_option,
    value: false,
  },
  email: {
    name: 'email',
    text: translations[lang].table_columns_settings_email_option,
    value: false,
  },
  language: {
    name: 'language',
    text: translations[lang].table_columns_settings_language_option,
    value: false,
  },
  keyQuestion: {
    name: 'keyQuestion',
    text: translations[lang].table_columns_settings_key_question_option,
    value: false,
  },
  categories: {
    name: 'categories',
    text:
      translations[lang].table_columns_settings_categories_option
      ?? 'Категорія відгуку',
    value: false,
  },
  tags: {
    name: 'tags',
    text: translations[lang].table_columns_settings_tags_option ?? 'Теги',
    value: false,
  },
  attachments: {
    name: 'attachments',
    text: translations[lang].table_columns_settings_attachments_option ?? 'Вкладення до відгуку',
    value: false,
  },
  // sentiment: {
  //   name: 'sentiment',
  //   text: translations[lang].table_columns_settings_sentiment_option ?? 'Sentiment',
  //   value: false,
  // },
});

export const analyticsDashboards = {
  1: 'https://app.powerbi.com/view?r=eyJrIjoiZDA4ZTVlNmYtODgzZS00OTAzLTliYzctYmE3YjExMjc2YWFmIiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
  110: 'https://app.powerbi.com/view?r=eyJrIjoiN2UxZjg2NzgtNDIwNC00YTZiLWE1YmYtMzEzYTY1NjNhYjk2IiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
  111: 'https://app.powerbi.com/view?r=eyJrIjoiYzYwYWUwOGMtOGUyNS00ZDcyLTgyOWYtODI1NmUxZGVkMzNlIiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
  166: 'https://app.powerbi.com/view?r=eyJrIjoiNGQ0ZDExOWYtOTE3OC00YTUwLTk0OTAtOWU2ZjM2NTIwNTVjIiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
  117: 'https://app.powerbi.com/view?r=eyJrIjoiZDhjYTBiOWItZTQzYi00OTY3LThmODMtZWIwNzQ0OWVjNTUxIiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
  136: 'https://app.powerbi.com/view?r=eyJrIjoiMzQ1NWUwOTQtNWE3ZC00ZWJhLWE0MjMtZTZlNWMxYmIxOGEzIiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
  158: 'https://app.powerbi.com/view?r=eyJrIjoiM2Y1ZTBjOTgtOWYwYS00Mzc4LWFiYTMtZDQ2YmQ4NjJmNzgxIiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
  160: 'https://app.powerbi.com/view?r=eyJrIjoiYWM4M2U2NGYtNzgwZC00OGMyLWI4MTMtNzAxNTkwOTM4YzJjIiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
  109: 'https://app.powerbi.com/view?r=eyJrIjoiY2ZiMWJkOWUtNDY1Ny00NjAyLThhOWItMjI5MWZkNGQ3NjI0IiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9',
};

export const networkIcon = (network: string) => {
  switch (network) {
    case 'phone':
      return PhoneIcon;
    case 'telegram':
      return TelegramIcon;
    case 'whatsapp':
      return WhatsAppIcon;
    case 'email':
      return EmailIcon;
    case 'viber':
      return ViberIcon;
    default:
      break;
  }
};

export const sentimentIcon = (type: string): any => {
  switch (type) {
    case 'negative':
      return NegativeIcon;
    case 'neutral':
      return NeutralIcon;
    case 'positive':
      return PositiveIcon;
  }
};

export const weeklyDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const providers = [{ name: 'Devinotel' }, { name: 'Uz' }];

export const GOOGLE_OAUTH_ERROR = 'errors.google.oauth.unauthorized;';
