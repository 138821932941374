import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { NpsDetailedDashboardStyles } from './styles';
import { useWindowSize } from 'usehooks-ts';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
import { analyticsApi, ApiGroupedNps, fieldsApi, TPostGroupedNps } from '../../../../api';
import { DataItem } from '../../../../pages';
const colors: string[] = ['#CFC4FF', '#F2C3DB', '#A7C4E9', '#F5EF67', '#6EAE93'];
type TNpsDetailedDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
export const NpsDetailedDashboard: FC<TNpsDetailedDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [npsData, setNpsData] = useState<ApiGroupedNps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostGroupedNps = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      fieldIDs: [858818],
      positiveAnswers: ['9', '10', '9/10', '10/10'],
      negativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      groupsBy: [{
        fieldID: 858829
      }, {
        fieldID: 858830
      }, {
        fieldID: 858831
      }, {
        metaKey: 'created_by_type'
      }, {
        metaKey: 'asc_type'
      }]
    };
    setLoading(true);
    analyticsApi.getGroupedNps(+companyID, filteredData).then(res => {
      console.log(res);
      setNpsData(res.data);
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/grouped/nps/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      fieldIDs: [858818],
      positiveAnswers: ['9', '10', '9/10', '10/10'],
      negativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      groupsBy: [{
        fieldID: 858829
      }, {
        fieldID: 858830
      }, {
        fieldID: 858831
      }, {
        metaKey: 'created_by_type'
      }, {
        metaKey: 'asc_type'
      }]
    }));
  }, [reQuery]);
  return <NpsDetailedDashboardStyles>
      {npsData && <div className="charts">
          {/* <div className="charts-one">
           </div> */}
          {/* <div className="charts-two">
           </div> */}
          {/* <div className="charts-three">
           </div> */}
          {/* <div className="charts-four">
           </div> */}
          {/* <div className="charts-five">
           </div> */}
          {/* <div className="charts-six">
           </div> */}
        </div>}
    </NpsDetailedDashboardStyles>;
};