import styled from 'styled-components';

export const QRPublishingNodesDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .bars {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 25px;

    &-item {
      display: flex;
      flex-direction: column;
      width: calc(50% - 15px);

      h6 {
        text-align: center;
      }
    }
  }
`;
