import styled from 'styled-components';

export const ReviewsByStatusesDashboardStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
  overflow: auto;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    gap: 10px;
  }

  .dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;

    &-chart {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(60% - 25px);
      gap: 25px;
    }

    &-total {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: calc(40% - 25px);
    }

    &-legend {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 10px 15px;

      &-item {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          width: 15px;
          height: 15px;
          border-radius: 50%;
        }
      }
    }

    &-count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      gap: 8px;
      background: rgb(172, 193, 207);
      box-shadow: 0 4px 8px rgba(172, 193, 207, 0.6);
      width: 100%;
      height: 150px;

      @media screen and (max-width: 1440px) {
        height: 100px;
      }

      p {
        font-size: 18px;
        text-align: center;

        @media screen and (max-width: 1440px) {
          font-size: 14px;
        }
      }

      h6 {
        font-size: 24px;
        text-align: center;
        font-weight: 700;

        @media screen and (max-width: 1440px) {
          font-size: 18px;
        }
      }
    }

    &-pie {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    &-bars {
      display: flex;
      flex-direction: column;
      gap: 15px;
      flex: 1;
      width: 100%;
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      h5 {
        width: 30%;
        flex-shrink: 0;
      }
    }

    &-segments {
      width: 100%;
      display: flex;
    }

    &-segment {
      height: 25px;
      position: relative;

      span {
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translate(0, -50%);
        color: #fff;
      }
    }
  }
`;
