import styled from 'styled-components';
export const CompanySettingsStyles = styled.div`
  padding: 20px 30px;
  max-width: 900px;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .col {
    display: flex;
    gap: 5px;
    flex-direction: column;

    &-child {
      display: flex;
    }
  }

  .horizontalLine {
    width: 100%;
    margin: 30px 0 10px;
    height: 1px;
    background-color: #e5e5e5;
  }

  .sourcesWrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: 32px;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(0, 0, 0);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0px 17px;
      transition: all 0.3s ease 0s;

      &.selected {
        background: rgb(0, 0, 0);
        color: #fff;
      }
    }
  }

  .selectField {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;
    position: relative;
    cursor: pointer;

    .placeholder {
      font-size: 14px;
      color: #6c6c6c;
    }

    .selectedValue {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrowDownActive {
        transform: rotate(180deg);
      }
    }

    .options {
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 100;
      width: 100%;
      overflow-y: scroll;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
      max-height: 200px;

      & > .option:last-child .row {
        border-bottom: none;
      }

      .option {
        .rowSel {
          width: 100%;
          align-items: center;
          border-bottom: 1px solid #c4c4c4;
          box-sizing: border-box;
          height: 42px;

          button {
            height: 100%;
            width: 100%;

            .labelContent {
              display: flex;
              padding-left: 15px;
              justify-content: flex-start;
              gap: 20px;
              align-items: center;
              width: 100%;
              height: 100%;
              color: #6e6e6e;
              box-sizing: border-box;

              svg {
                flex-shrink: 0;
              }

              span {
                font-size: 12px;
                text-align: left;
              }

              .default {
                flex-shrink: 0;
                cursor: pointer;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                background-color: #ffffff;
                border: 1px solid #e5e5e5;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }
  }

  .statusesContainer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px 10px;
  }

  .enabled {
    margin-top: 15px;
    max-width: 400px;
    width: 100%;

    .fields {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 50px;
    }
  }

  .depthNotificationsResponsible {
    .formContainer {
      margin-top: 20px;

      form {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .row {
          display: flex;
          align-items: center;
          gap: 10px;

          & > div {
            width: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
`;