import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { QRPublishingNodesDashboardStyles } from './styles';
import { BarChart, HorizontalBar, PieChart } from '../../analyticsCharts';
import { analyticsApi, ApiCsi, ApiCsiRegionRating, ApiQRPublishingNodes, fieldsApi, TPostDashboardData, TPostQRPublishing } from '../../../../api';
import { Loader } from '../../../atoms';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type TQRPublishingNodesDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#FFDB4D', '#5B5AFF'];
export const QRPublishingNodesDashboard: FC<TQRPublishingNodesDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [qrData, setQRData] = useState<ApiQRPublishingNodes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const maxNodes = useMemo(() => Math.max(...qrData.map(el => el.nodesCount)), [qrData]);
  const maxReviews = useMemo(() => Math.max(...qrData.map(el => el.reviewCount)), [qrData]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostQRPublishing = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    };
    setLoading(true);
    analyticsApi.getQRPublishingNodes(+companyID, filteredData).then(res => {
      setQRData(res.data);
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/qr-publishing-nodes/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    }));
  }, [reQuery]);
  return <QRPublishingNodesDashboardStyles>
      {loading && <Loader />}

      {!loading && <div className="bars">
          <div className="bars-item">
            <h6>Підключено точок ЦНАП</h6>
            <HorizontalBar width={dimensions.width / 2.5} height={dimensions.height} hideAxis data={qrData.map(el => ({
          label: el.state,
          value: el.nodesCount / maxNodes * 100,
          fill: colors[1],
          count: el.nodesCount
        })).sort((a, b) => b.value - a.value)} />
          </div>
          <div className="bars-item">
            <h6>Отримано заповнених анкет</h6>
            <HorizontalBar width={dimensions.width / 2.5} height={dimensions.height} hideAxis data={qrData.map(el => ({
          label: el.state,
          value: el.reviewCount / maxReviews * 100,
          fill: colors[0],
          count: el.reviewCount
        })).sort((a, b) => b.value - a.value)} />
          </div>
        </div>}

    </QRPublishingNodesDashboardStyles>;
};