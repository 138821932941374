import React, { useEffect } from 'react';
import { LoginStyles } from './styles';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '../../state';
import { getUser } from '../../state/thunk/accessesThunk';
import { LockIcon } from '../../assets/icons/LockIcon';
export const AccessRequestThankYouPage = () => {
  const dispatch = useAppDispatch();
  const {
    data
  } = useAppSelector(state => state.user);
  useEffect(() => {
    if (data?.id) {
      dispatch(getUser({
        userID: data?.id!
      }));
    }
  }, []);
  return <LoginStyles>
      <Helmet>
        <title>Authorization Voicer</title>
      </Helmet>

      <div className="formWrapper">
        <div className="formContainer">
          <LockIcon />
          <h2>
            Ваша заявка в обробці. Очікуйте листа про схвалення доступу на
            електронну пошту.
          </h2>
        </div>
      </div>
    </LoginStyles>;
};