import styled from 'styled-components';

export const TablePaginationStyles = styled.div<{ mobileVersion: boolean }>`
  padding: ${({ mobileVersion }) => (mobileVersion ? '8px 18px 8px 8px' : '16px 36px 16px 16px')};
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  select {
    border: none;
    background: none;
  }

  .arrowsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .arrows {
      display: flex;
      justify-content: space-between;
    }

    .pageCounter {
      color: #000;
      padding: 0 16px;

      span {
        font-weight: bold;
        font-size: ${({ mobileVersion }) => (mobileVersion ? '14px' : '16px')};
      }

      .currentPage {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({ mobileVersion }) => (mobileVersion ? '24px' : '48px')};
        height: ${({ mobileVersion }) => (mobileVersion ? '24px' : '48px')};

        text-align: center;
        background-color: transparent;
        border: 2px solid #0c000533;
        font-size: ${({ mobileVersion }) => (mobileVersion ? '14px' : '16px')};
        font-weight: 500;
        border-radius: 12px;
        &::placeholder {
          font-size: ${({ mobileVersion }) => (mobileVersion ? '14px' : '16px')};
          color: #0c0005;
        }

        &.notValid {
          border: 1px solid #ff0101;
        }
      }
    }

    span {
      font-weight: 400;
      color: #0c000580;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;
