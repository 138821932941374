import React, { useEffect, useRef, useState } from 'react';
import { MassmailerStyles } from './MassmailerStyles';
import { useAppSelector } from '../../state';
import { TabMailKeys, tabsEdit } from './utils';
import { Helmet } from 'react-helmet-async';
import { CampaignTable } from './campaignTable/CampaignTable';
import { CampaignEmail, CampaignSms, CompanyFilter, CustomTabsMenu, Modal, SubHeader, Templaters, TransparentButton } from '../../components';
import { ApiEmailCampaigns, ApiEmailHistoryItem, ApiSmsCampaigns, massMailingApi, companyApi, ApiViberHistoryItem } from '../../api';
import { CompaniesMainInfo } from '../../entities';
import { Sms } from './sms';
import molData from './email/email.json';
import _ from 'lodash';
import { CustomSelect } from '../../components/molecules/customSelect/CustomSelect';
import { ApiCampaign } from '../../api/entities/ApiCampaigns';
export const Massmailer = () => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [selectedTab, setSelectedTab] = useState(tabsEdit(interfaceLanguage)[0].key);
  const channels = [{
    channel: 'SMS'
  }, {
    channel: 'Viber'
  }, {
    channel: 'Email'
  }];
  const [selectedChanell, setSelectedChanell] = useState<{
    channel: 'SMS' | 'Viber' | 'Email';
  }>();
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [campaignsSms, setCampaignsSms] = useState<ApiSmsCampaigns[]>([]);
  const [campaignsViber, setCampaignsViber] = useState<ApiSmsCampaigns[]>([]);
  const [selectedCampaignSms, setSelectedCampaignSms] = useState<ApiSmsCampaigns>();
  const [campaignsEmail, setCampaignsEmail] = useState<ApiEmailCampaigns[]>([]);
  const [selectedCampaignEmail, setSelectedCampaignEmail] = useState<ApiEmailCampaigns>();
  const [addCampaign, setAddCampaign] = useState<boolean>(false);
  const [templater, setTemplater] = useState<boolean>(false);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      acc[`${item.id}`] = item;
      return acc;
    }, {} as {
      [key: string]: CompaniesMainInfo;
    });
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const compID = Object.values(apiCompanies)[0].id;
      const filterParams: {
        companyID: number;
      } = {
        companyID: compID
      };
      filterParamsRef.current = filterParams;
      setReQuery(!reQuery);
    }
  };
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, []);
  useEffect(() => {
    if (selectedTab === TabMailKeys.campains && filterParamsRef.current.companyID && selectedChanell?.channel === 'SMS') {
      massMailingApi.massMailingSmsCampaignList(+filterParamsRef.current.companyID).then(res => {
        const sortedData = _.orderBy(res.data, ['createdAt'], ['desc']);
        if (res.statusCode === 200) {
          setCampaignsSms(sortedData);
        }
      });
    }
    if (selectedTab === TabMailKeys.campains && filterParamsRef.current.companyID && selectedChanell?.channel === 'Viber') {
      massMailingApi.massMailingViberCampaignList(+filterParamsRef.current.companyID).then(res => {
        const sortedData = _.orderBy(res.data, ['createdAt'], ['desc']);
        if (res.statusCode === 200) {
          setCampaignsViber(sortedData);
        }
      });
    }
    if (selectedTab === TabMailKeys.campains && filterParamsRef.current.companyID) {
      setCampaignsSms([]);
    }
    if (selectedTab === TabMailKeys.campains && selectedChanell?.channel === 'Email') {
      massMailingApi.massMailingEmailCampaignList(+filterParamsRef.current.companyID).then(res => {
        const sortedData = _.orderBy(res.data.campaigns, ['createdAt'], ['desc']);
        if (res.statusCode === 200) {
          // @ts-ignore
          setCampaignsEmail(sortedData);
        }
      });
      // const emailHistoryItems: ApiEmailHistoryItem[] = molData;

      // const groupedItems = emailHistoryItems.reduce<{
      //   [key: number]: ApiEmailCampaigns;
      // }>((acc, item) => {
      //   const campaignID = item.emailCampaignID;
      //   if (!acc[campaignID]) {
      //     acc[campaignID] = {
      //       createdAt: item.createdAt,
      //       id: campaignID,
      //       items: [],
      //       itemsDelivered: 0,
      //       itemsFailed: 0,
      //       itemsOpened: 0,
      //       itemsUnsubscribed: 0,
      //       formOpened: 0,
      //       formSubmitted: 0,
      //       itemAccepted: 0,
      //       itemsTotal: 0,
      //       name: `campaign${campaignID}`,
      //     };
      //   }

      //   acc[campaignID].items.push(item);
      //   acc[campaignID].itemsDelivered += item.delivered ? 1 : 0;
      //   acc[campaignID].itemsFailed += item.failed ? 1 : 0;
      //   acc[campaignID].itemsOpened += item.opened ? 1 : 0;
      //   acc[campaignID].itemsUnsubscribed += item.unsubscribed ? 1 : 0;
      //   acc[campaignID].formOpened += item.formOpened ? 1 : 0;
      //   acc[campaignID].formSubmitted += item.formSubmitted ? 1 : 0;
      //   acc[campaignID].itemAccepted += item.accepted ? 1 : 0;
      //   acc[campaignID].itemsTotal += 1;

      //   return acc;
      // }, {});

      // const emailCampaigns: ApiEmailCampaigns[] = Object.values(groupedItems);
      // setCampaignsEmail(emailCampaigns);
    }
  }, [selectedTab, filterParamsRef.current, addCampaign, templater, selectedChanell]);
  const handleDelete = async (id: number) => {
    await massMailingApi.deleteMassSmsMailingCampaign(id);
    setCampaignsSms(prevCamp => prevCamp.filter(e => e.id !== id));
  };
  return <MassmailerStyles>
      <Helmet>
        <title>Масові розсилки</title>
      </Helmet>
      <SubHeader title="Масові розсилки" hideBorderBottom />
      <CustomTabsMenu tabs={tabsEdit(interfaceLanguage)} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div className="companyFilterContainer">
        {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={params => {
        filterParamsRef.current = params;
      }} setReQuery={() => setReQuery(!reQuery)} />}
        {selectedTab === TabMailKeys.campains && <CustomSelect containerStyle={{
        marginTop: 0
      }} name="channels" options={channels}
      // multiple
      placeholder="Канал комунікації" labelField="channel" selected={selectedChanell} onChange={value => {
        setSelectedChanell(value);
      }} />}
      </div>
      {filterParamsRef.current.companyID && selectedTab === TabMailKeys.createCampain && <div className="campaign-table">
            <CampaignTable ref={filterParamsRef} setAddCampaign={() => setAddCampaign(true)} setTemplater={() => setTemplater(true)} companyID={+filterParamsRef.current.companyID} />
          </div>}
      <div className="history-container">
        {selectedChanell?.channel === 'SMS' && selectedTab === TabMailKeys.campains && !!campaignsSms.length && campaignsSms.map(campaign => <CampaignSms handleDelete={handleDelete} selectedCampaign={selectedCampaignSms} key={campaign.id} campaign={campaign} setSelectedCampaign={setSelectedCampaignSms} type="SMS" companyID={+filterParamsRef.current.companyID} />)}
        {selectedChanell?.channel === 'Viber' && selectedTab === TabMailKeys.campains && !!campaignsViber.length && campaignsViber.map(campaign => <CampaignSms handleDelete={handleDelete} selectedCampaign={selectedCampaignSms} key={campaign.id} campaign={campaign} setSelectedCampaign={setSelectedCampaignSms} type="Viber" companyID={+filterParamsRef.current.companyID} />)}

        {selectedChanell?.channel === 'Email' && selectedTab === TabMailKeys.campains && !!campaignsEmail.length && campaignsEmail.map(campaign => <CampaignEmail selectedCampaign={selectedCampaignEmail} key={campaign.id} campaign={campaign} setSelectedCampaign={setSelectedCampaignEmail} type="Email" companyID={+filterParamsRef.current.companyID} />)}
      </div>
      {addCampaign && <Modal title="Створення розсилки" onClose={() => setAddCampaign(false)}>
          <Sms filters={filterParamsRef.current} setAddCampaign={setAddCampaign} companyID={+filterParamsRef.current.companyID} />
        </Modal>}
      {templater && <Modal title="Редактор шаблонів" onClose={() => setTemplater(false)}>
          <Templaters setTemplater={setTemplater} companyID={+filterParamsRef.current.companyID} />
        </Modal>}
    </MassmailerStyles>;
};