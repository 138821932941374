import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ReviewsByStatusesDashboardStyles } from './styles';
import { analyticsApi, TCountsByStatusInRegion, TCountsByStatusTotal, TPostReviewsByStatuses } from '../../../../api';
import { Table } from '../../../organisms';
import { setSaveFilters, setSaveLink, useAppDispatch, useAppSelector } from '../../../../state';
import { reviewsStatusIconsAndText } from '../../../../constants';
import { PieChart } from '../../analyticsCharts';
const colors: string[] = ['#FF3800', '#0015ff', '#FF831A', '#5B5AFF', '#D7F64D', '#e82727', '#FFDB4D', '#89DB33', '#CC2E4F', '#FFDB4D', '#995aff'];
type TRegionReviewsByStatusesDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
export const RegionReviewsByStatusesDashboard: FC<TRegionReviewsByStatusesDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [counts, setCounts] = useState<TCountsByStatusInRegion[]>([]);
  const [totalCounts, setTotalCounts] = useState<TCountsByStatusTotal[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const reviewCount = useRef<number>(0);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostReviewsByStatuses = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    };
    setLoading(true);
    analyticsApi.getRegionReviewsByStatuses(+companyID, filteredData).then(res => {
      if (res.statusCode === 200) {
        reviewCount.current = res.data.reviewsCount;
        setCounts(res.data.countsByStatusInRegion);
        setTotalCounts(res.data.countsByStatusTotal);
      }
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/regions-reviews-by-statuses/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    }));
  }, [reQuery]);
  return <ReviewsByStatusesDashboardStyles>
      <div className="dashboard">
        <div className="dashboard-chart">
          <div className="dashboard-legend">
            {Object.entries(reviewsStatusIconsAndText(interfaceLanguage)).map(([key, value], index) => <div key={value.text} className="dashboard-legend-item">
                <span style={{
              background: `${colors[index]}`
            }} />
                <p>{value.text}</p>
              </div>)}
          </div>
          <div className="dashboard-bars">
            {counts.map(el => <div key={el.regionID} className="dashboard-row">
                <h5>{el.regionName}</h5>
                <div className="dashboard-segments">
                  {el.counts.map((e, i) => <div className="dashboard-segment" style={{
                width: `${e.part}%`,
                background: `${colors[i]}`
              }}>
                      {e.part > 10 && <span>{e.part}%</span>}
                    </div>)}
                </div>
              </div>)}
          </div>
        </div>
        <div className="dashboard-total">
          <div className="dashboard-count">
            <p>Всього відгуків</p>
            <h6>{reviewCount.current}</h6>
          </div>
          <div className="dashboard-pie">
            <h2>Кількість відгуків і їх статус</h2>
            <PieChart width={dimensions.height / 3} height={dimensions.height / 3} data={totalCounts.map((el, index) => ({
            label: reviewsStatusIconsAndText(interfaceLanguage)[el.status].text,
            value: el.part,
            fill: colors[el.status],
            count: el.count
          }))} chartLegend />
          </div>
        </div>
      </div>
    </ReviewsByStatusesDashboardStyles>;
};