export enum TabKeys {
  csi = 'csi',
  csiDetailed = 'csiDetailed',
  npsDetailed = 'npsDetailed',
  csiRegionRatings = 'csiRegionRatings',
  ratingPopulationOne = 'ratingPopulationOne',
  ratingPopulationTwo = 'ratingPopulationTwo',
  ratingPopulationThree = 'ratingPopulationThree',
  ratingPopulationFour = 'ratingPopulationFour',
  ratingPopulationFive = 'ratingPopulationFive',
  ratingPopulationSix = 'ratingPopulationSix',
  ratingReviewsOne = 'ratingReviewsOne',
  ratingReviewsTwo = 'ratingReviewsTwo',
  ratingReviewsThree = 'ratingReviewsThree',
  ratingReviewsFour = 'ratingReviewsFour',
  ratingReviewsFive = 'ratingReviewsFive',
  ratingReviewsSix = 'ratingReviewsSix',
  ratingVrmReviews = 'ratingVrmReviews',
  complexRating = 'complexRating',
  locationDissatisfaction = 'locationDissatisfaction',
  locationOutOfRating = 'locationOutOfRating',
  locationWithoutReviews = 'locationWithoutReviews',
  generalProcessDissatisfaction = 'generalProcessDissatisfaction',
  staffDissatisfaction = 'staffDissatisfaction',
  complexGeneral = 'complexGeneral',
  servicesList = 'servicesList',
  qrPublishing = 'qrPublishing',
  qrPublishingPie = 'qrPublishingPie',
  qrPublishingNodes = 'qrPublishingNodes',
  reviewsByStatuses = 'reviewsByStatuses',
  regionReviewsByStatuses = 'regionReviewsByStatuses',
  dynamic = 'dynamic',
}

export type DataItem = {
  label: string;
  value: number;
  fill?: string;
  count?: number;
};

export type CurveItem = {
  label?: string;
  x: number;
  y: number;
  fill?: string;
  count?: number;
};

export type TTab = {
  key: TabKeys;
  text: string;
};

export const tabs: TTab[] = [
  {
    key: TabKeys.csi,
    text: "Показники рівня задоволеності суб'єктів звернення (CSI)",
  },
  {
    key: TabKeys.locationDissatisfaction,
    text: 'Основні причини незадоволеності місцем надання послуг',
  },
  {
    key: TabKeys.generalProcessDissatisfaction,
    text: 'Основні причини незадоволеності загальним процесом надання послуг',
  },
  {
    key: TabKeys.staffDissatisfaction,
    text: 'Основні причини незадоволеності персоналом',
  },
  {
    key: TabKeys.servicesList,
    text: 'Основні причини незадоволеності повнотою переліку послуг',
  },
  {
    key: TabKeys.csiDetailed,
    text: "Статистика по показнику рівня задоволеності суб'єктів звернення з дезагрегацією оціночних даних (CSI)",
  },
  {
    key: TabKeys.npsDetailed,
    text: 'Статистика по індексу лояльності суб’єктів звернення з дезагрегацією оціночних даних (NPS)',
  },
  {
    key: TabKeys.csiRegionRatings,
    text: 'Рейтинг регіонів за результатами оцінки рівня задоволеності суб’єктів звернення',
  },
  {
    key: TabKeys.ratingPopulationOne,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс І категорії)',
  },
  {
    key: TabKeys.ratingPopulationTwo,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс ІІ категорії)',
  },
  {
    key: TabKeys.ratingPopulationThree,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс ІІІ категорії)',
  },
  {
    key: TabKeys.ratingPopulationFour,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс ІV категорії)',
  },
  {
    key: TabKeys.ratingPopulationFive,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс V категорії)',
  },
  {
    key: TabKeys.ratingPopulationSix,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості населення (офлайн-фронт-офіс VI категорії)',
  },
  {
    key: TabKeys.ratingReviewsOne,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс І категорії)',
  },
  {
    key: TabKeys.ratingReviewsTwo,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс ІІ категорії)',
  },
  {
    key: TabKeys.ratingReviewsThree,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс ІІІ категорії)',
  },
  {
    key: TabKeys.ratingReviewsFour,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс ІV категорії)',
  },
  {
    key: TabKeys.ratingReviewsFive,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс V категорії)',
  },
  {
    key: TabKeys.ratingReviewsSix,
    text: 'Рейтинг ЦНАП та ТП відповідно до кількості відгуків (офлайн-фронт-офіс VІ категорії)',
  },
  {
    key: TabKeys.ratingVrmReviews,
    text: 'Рейтинг ВРМ відповідно до кількості відгуків',
  },
  {
    key: TabKeys.complexRating,
    text: 'Рейтинг ВРМ за результатами оцінки рівня задоволеності суб’єктів звернення',
  },
  {
    key: TabKeys.locationOutOfRating,
    text: 'ЦНАП, у яких показник рівня задоволеності суб’єктів звернення у звітному періоді недосягнутий',
  },
  {
    key: TabKeys.locationWithoutReviews,
    text: 'ЦНАП, у яких відсутні відгуки',
  },
  {
    key: TabKeys.complexGeneral,
    text: 'Результати оцінки рівня задоволеності суб’єктів звернення в ЦНАП',
  },
  {
    key: TabKeys.qrPublishing,
    text: 'Стан розміщення QR-кодів з посиланням на опитування в ЦНАП',
  },
  {
    key: TabKeys.qrPublishingPie,
    text: 'Аналітика розміщення QR-кодів з посиланням на опитування в ЦНАП',
  },
  {
    key: TabKeys.qrPublishingNodes,
    text: 'Стан розміщення QR-кодів з посиланням на опитування в ЦНАП в розрізі регіонів',
  },
  {
    key: TabKeys.reviewsByStatuses,
    text: 'Відгуки у розрізі статусів',
  },
  {
    key: TabKeys.regionReviewsByStatuses,
    text: 'Стан опрацювання відгуків',
  },
  {
    key: TabKeys.dynamic,
    text: 'Динаміка показників рівня задоволеності',
  },
];
